(function() {
  'use strict';

  function FormsService($q, $log, moment, LocalizationService, EMPTY_SIGNATURE) {
    var Form = function(fields) {
      this.fields = [];
      if (fields) {
        this.extend(fields);
      }
    };

    Form.prototype.addGroup = function(data) {
      var group = angular.copy(data || {});
      group.fieldGroup = [];

      this.fields.push(group);
      return group;
    };

    Form.prototype.addField = function(data, group) {
      // This might seem to be not necessary but this persists the order
      var _this = this;
      var field = { template: 'loading...' };
      if (data && !_.isFunction(data.then)) {
        $log.debug('Event Form: Direct field');
        field = _this.buildField(data);
      }

      if (group) {
        group.fieldGroup.push(field);
      } else {
        this.fields.push(field);
      }

      if (data && _.isFunction(data.then)) {
        $log.debug('Event Form: Promise field');
        data.then(function(data) {
          var idx;
          if (group) {
            idx = group.fieldGroup.indexOf(field);
            group.fieldGroup[idx] = _this.buildField(data);
          } else {
            idx = _this.fields.indexOf(field);
            _this.fields[idx] = _this.buildField(data);
          }
        }).catch(function(error) {
          console.log(error);
        });
      }
    };

    Form.prototype.removeField = function(id, group) {
      if (group) {
        _.remove(group.fieldGroup, function(field) { return field.key === id; });
      } else {
        _.remove(this.fields, function(field) { return field.key === id; });
      }
    };

    Form.prototype.removeGroup = function(group) {
      // FIX ME: field groups are not removed from the list of fields
      this.fields = _.without(this.fields, group);
    };

    Form.prototype.buildField = function(data) {
      var _this = this;
      if (!data.type) {
        data.type = 'discrete';
      }

      if (data.type === 'hierarchy') {
        data.type = 'tree';
      }

      // Add unique attr
      if (data.unique) {
        data.ngModelAttrs = data.ngModelAttrs || {};
        data.ngModelAttrs.unique = {
          bound: 'ng-unique',
          attribute: 'unique'
        };

        data.ngModelAttrsValues = data.ngModelAttrsValues || [];

        data.ngModelAttrsValues.push(
          {
            name: 'unique',
            value: 'true'
          }
        );
      }

      var field = {};
      var required = data.required ? data.required : false;

      if (data.noFormControl) {
        var template = '<div class="row no-form-control"> ' +
          '<div class="col-xs-12">' +
          '<label for="{{::id}}" class="control-label" ng-if="to.label">' +
              '{{to.label}}' +
              '<i ng-if="to.required && !to.disabled" class="icon-star required"></i>' +
            '<i ng-if="to.disabled" class="icon-lock disabled"></i>' +
          '</label><div>' + data.template + '</div>' +
          '<p ng-if="to.helpText" class="text-gray">{{to.helpText}}</p></div></div>';
        field = {
          id: data.id,
          key: data.key || data.id,
          controller: data.controller,
          template: template,
          type: data.type,
          templateOptions: {
            fieldId: data.fieldId,
            label: data.label,
            onChange: data.onChange,
            required: required,
            readOnly: data.readOnly ? data.readOnly : false,
            disabled: data.disabled ? data.disabled : false,
            kzType: data.kzType,
            className: data.className,
            sectionId: data.sectionId,
            fileOptions: data.fileOptions,
            turnitInOptions: data.turnitInOptions
          }
        };
      } else {
        field = {
          id: data.id,
          type: data.type,
          name: data.name || data.id,
          key: data.key || data.id,
          ngModelAttrs: {},
          defaultValue: data.type !== 'boolean' ? data.defaultValue : false,
          expressionProperties: data.expressionProperties ? data.expressionProperties : {},
          templateOptions: {
            fieldId: data.fieldId,
            label: data.label,
            originalType: data.originalType,
            onChange: data.onChange,
            onClick: data.onClick,
            required: data.type !== 'boolean' ? required : false,
            readOnly: data.readOnly ? data.readOnly : false,
            disabled: data.disabled ? data.disabled : false,
            kzType: data.kzType,
            className: data.className,
            sectionId: data.sectionId,
            fileOptions: data.fileOptions,
            turnitInOptions: data.turnitInOptions
          },
          validation: {
            messages: {
              required: function() {
                return 'This field is required';
              },
              required2: function() {
                return 'This field is required';
              },
              fileStatus: function() {
                return 'Please wait until all files are uploaded';
              },
              turnitinRequired: function() {
                return 'Score is required';
              },
              email: function(viewValue) {
                return viewValue + ' is not a valid email address';
              },
              emailDomain: function(viewValue) {
                if (_.isString(viewValue)) {
                  return viewValue + ' is not from an allowed domain';
                }

                return 'An email from a not allowed domain';
              },
              unique: function(_viewValue, _modelValue, scope) {
                return scope.to.label + ' is not unique';
              }
            }
          }
        };

        if (data.helpText) {
          field.templateOptions.helpText = data.helpText;
        }
        if (data.helpTextHtml) {
          field.templateOptions.helpTextHtml = data.helpTextHtml;
        }


        if (data.type === 'select' || data.type === 'email_select') {
          if (data.required) {
            field.validators = {
              required: {
                expression: function(_viewValue, modelValue) {
                  var value = modelValue;
                  if (value === undefined) {
                    return false;
                  }

                  return !_.isEmpty(value);
                }
              }
            };
          }
        }

        if (data.getTitle) {
          field.templateOptions.getTitle = data.getTitle;
        }

        if (data.type === 'discrete_multiple') {
          field.ngModelAttrs.closeOnSelect = {
            // bound: 'ng-close-on-select',
            attribute: 'close-on-select'
          };

          field.templateOptions.closeOnSelect = false;

          if (data.required) {
            field.validators = {
              required: {
                expression: function(_viewValue, modelValue) {
                  var value = modelValue;
                  if (value === undefined) {
                    return false;
                  }

                  return value.length > 0;
                }
              }
            };
          }
        }

        var customOptions = ['expressionProperties', 'hideExpression', 'ngModelAttrs'];

        _.forEach(customOptions, function(option) {
          if (data[option]) {
            field[option] = data[option];
          }
        });

        if (data.ngModelAttrsValues) {
          _.forEach(data.ngModelAttrsValues, function(attr) {
            field.templateOptions[attr.name] = attr.value;
          });
        }

        if (data.type === 'email') {
          field.templateOptions.type = 'email';
        }

        if (data.placeholder) {
          field.templateOptions.placeholder = data.placeholder;
        }

        var types = [
          'select', 'discrete', 'discrete_multiple', 'likert', 'email_multiple',
          'email_select', 'datetime', 'date', 'checkboxes_grid'
        ];
        if (types.indexOf(data.type) > -1) {
          if (data.options !== undefined) {
            field.templateOptions.loading = true;
            $q.when(data.options)
              .then(function(options) {
                options = angular.copy(options);
                if (['select', 'discrete', 'likert'].indexOf(data.type) > -1 && !data.required) {
                  options.unshift({ _id: undefined, name: ' - n/a - ' });
                }

                field.templateOptions.options = options;
              })
              .finally(function() {
                field.templateOptions.loading = false;
              });
          }

          if (!field.templateOptions.placeholder) {
            field.templateOptions.placeholder = data.label;
          }
          field.templateOptions.itemLabel = data.itemLabel ? data.itemLabel : 'name';
          field.templateOptions.itemValue = data.itemValue ? data.itemValue : '_id';
        }

        if (data.type === 'date' || data.type === 'datetime') {
          var dateTimeFormats = LocalizationService.getDateTimeFormats();
          if (!data.options) {
            field.type = data.type;
            field.templateOptions.options = {
              locale: {
                format: data.type === 'datetime' ?
                  dateTimeFormats.datetime :
                  dateTimeFormats.dateonly,
                firstDay: 1
              }
            };

            field.templateOptions.startField = data.startField;
            field.templateOptions.endField = data.endField;
          }
          field.validators = {
            dateBelow1900: {
              expression: function(viewValue, modelValue) {
                var value = viewValue || modelValue;
                if (value === undefined || value === null) {
                  return true;
                }

                try {
                  var dt = moment(value);
                  if (dt.year() < 1900) {
                    return false;
                  }
                } catch (err) {
                  return false;
                }
                return true;
              },
              message: '$viewValue + " is not above year 1900"'
            }
          };
        }

        if (data.type === 'numeric') {
          field.templateOptions.minlength = data.minlength;
          field.templateOptions.maxlength = data.maxlength;
          field.validators = {
            isInteger: {
              expression: function(viewValue, modelValue) {
                var value = viewValue || modelValue || null;
                if (value === undefined || value === null) {
                  return true;
                }

                try {
                  value = parseInt(value);
                } catch (err) {
                  console.log(err);
                }

                return _.isNumber(value);
              },
              message: '$viewValue + " is not an integer"'
            },
            IsWithinRange: {
              expression: function(viewValue, modelValue) {
                var value = viewValue || modelValue || null;
                if (value === undefined || value === null) {
                  return true;
                }

                var minlength = field.templateOptions.minlength;
                var maxlength = field.templateOptions.maxlength;

                if (!_.isUndefined(minlength) && !_.isUndefined(maxlength)) {
                  return value >= minlength && value <= maxlength;
                } else if (!_.isUndefined(minlength)) {
                  return value >= minlength;
                } else if (!_.isUndefined(maxlength)) {
                  return value <= maxlength;
                }

                return true;
              },
              message: function($viewValue) {
                var minlength = field.templateOptions.minlength;
                var maxlength = field.templateOptions.maxlength;

                if (!_.isUndefined(minlength) && !_.isUndefined(maxlength)) {
                  return $viewValue + ' not in range of ' + minlength + ' and ' + maxlength;
                } else if (!_.isUndefined(minlength)) {
                  return $viewValue + ' is not higher or equal than ' + minlength;
                } else if (!_.isUndefined(maxlength)) {
                  return $viewValue + ' is not lower or equal than ' + maxlength;
                }
              }
            },
            HasCorrectNumberOfStep: {
              expression: function(viewValue, modelValue) {
                var value = viewValue || modelValue || null;
                if (value === undefined || value === null) {
                  return true;
                }

                // hack: ignoring field.templateOptions.step
                var regexp = /^-?\d+(\.\d{1,3})?$/;
                return regexp.test(value);
              },
              message: function() {
                return 'Only 3 decimal places maximum is allowed.';
              }
            }
          };
        }

        if (data.type === 'typeahead') {
          field.templateOptions.inputType = data.inputType || 'text';
          field.templateOptions.allowCustom = data.allowCustom;
          field.templateOptions.tpOptions = data.tpOptions;
        }

        if (data.type === 'email_list') {
          field.validators = {
            isEmailList: {
              expression: function(viewValue) {
                var value = viewValue;
                if (value === undefined || value === null || value === '') {
                  return true;
                }

                // eslint-disable-next-line max-len
                var emailPattern = new RegExp('^([A-Za-z0-9_\\-\\.\\+\\\'])+\@([A-Za-z0-9_\\-\\.])+\\.([A-Za-z]{2,4})$');
                var separators = new RegExp('\s*[;|,]\s*');

                var emails = value.split(separators);
                var validEmails = _.filter(emails, function(email) {
                  return emailPattern.test(email.trim());
                });

                return validEmails.length === emails.length;
              },
              message: '$viewValue + " is not an valid list of emails. Please use a colon ' +
                       'as a separator such as e.g email1@domain.com, email2@domain.com"'
            }
          };
        }

        if (data.type === 'tree' && data.required) {
          field.validators = {
            required2: {
              expression: function(_viewValue, modelValue) {
                var value = modelValue;
                console.log('here doing a value of a ', value, field.templateOptions.label);
                if (value === undefined) {
                  return false;
                }

                return value.length > 0;
              }
            }
          };
        }

        if (data.type === 'signature' && data.required) {
          field.defaultValue = EMPTY_SIGNATURE;
          field.validators = {
            required: {
              expression: function(_viewValue, modelValue) {
                if (_.isUndefined(modelValue)) {
                  return false;
                }
                return !_.isEmpty(modelValue) && modelValue !== EMPTY_SIGNATURE;
              }
            }
          };
        }

        if (data.type === 'username') {
          field.validators = {
            isUsernameAdmin: {
              expression: function(viewValue, modelValue) {
                var value = viewValue || modelValue;
                return value !== 'admin';
              },
              message: '"This username cannot be taken"'
            }
          };
        }

        if (data.type === 'json') {
          var toString = function(data) {
            return data ? JSON.stringify(data, null, 2) : '';
          };

          field.templateOptions.rows = 10;
          field.formatters = [toString];
          field.validators = {
            validJson: {
              expression: function(viewValue) {
                if (_.isUndefined(viewValue) || viewValue === '') {
                  return true;
                }

                try {
                  JSON.parse(viewValue);
                } catch (e) {
                  return false;
                }

                return true;
              },
              message: '"The string is not valid JSON"'
            },
            containsInvalidColorNames: {
              expression: function(viewValue) {
                try {
                  var json = JSON.parse(viewValue);
                  var invalidKeys = Object.keys(json).filter(function(prop) {
                    return !/^--color-.+/.test(prop);
                  });
                  if (invalidKeys.length > 0) {
                    return false;
                  }
                } catch (e) {
                  // we ignore it
                }

                return true;
              },
              message: '"There are invalid color names in your JSON"'
            }
          };
        }
      }

      if (data.type === 'file') {
        var fileUploadRequired = typeof data.required === 'undefined'
          ? false
          : data.required;
        field.templateOptions.acceptedFileTypes = data.acceptedFileTypes;
        field.templateOptions.direct = data.direct;
        field.templateOptions.docId = data.docId;
        field.templateOptions.fileOptions = data.fileOptions;
        field.templateOptions.hideList = data.hideList;
        field.templateOptions.required = fileUploadRequired;
        field.templateOptions.onModelUpdated = data.onModelUpdated;
        field.validators = {
          // If we don't specifically define this required,
          // for some reason, the form will think the file is required
          // even when it is not :(
          required: {
            expression: function(_viewValue, modelValue) {
              if (!fileUploadRequired) {
                return true;
              }

              if (_.isUndefined(modelValue)) {
                return false;
              }

              return !_.isEmpty(modelValue);
            }
          }
        };

        if (data.maxFilesize) {
          field.validators = _.assignIn({}, field.validators, {
            maxFilesize: {
              expression: function(viewValue, modelValue) {
                var value = viewValue || modelValue;

                if (!value || (value && !value.filesize)) {
                  // We want to not check the file size if there is no file there
                  return true;
                }

                // If the maxFilesize is larger, then we're under the limit
                return data.maxFilesize > value.filesize;
              },
              message: '"The file you selected is too large!"'
            }
          });
        }

        if (data.acceptedFileTypes) {
          field.validators = _.assignIn({}, field.validators, {
            acceptedFileTypes: {
              expression: function(viewValue, modelValue) {
                var value = viewValue || modelValue;

                if (!value || (value && !value.filetype)) {
                  // We want to not check the file type if there is no file there
                  return true;
                }

                // Since acceptedFileTypes can be either a general format like 'image/*'
                // or more specific format like 'image/png',
                // we are only interested in the first part, e.g. 'image/'
                var accept = data.acceptedFileTypes.split('*')[0];
                return value.filetype === accept || value.filetype.startsWith(accept);
              },
              message: '"The file you selected has the wrong format!"'
            }
          });
        }
      }

      if (data.controller) {
        field.controller = data.controller;
      }

      if (data.validators) {
        field.validators = _.assignIn({}, field.validators || {}, data.validators);
      }

      if (_.isFunction(data.loader)) {
        field.templateOptions.loader = data.loader;
        field.templateOptions.applyLoader = function(res) {
          var idx = _.indexOf(_this.fields, field);
          _this.fields[idx] = _this.buildField(res);
        };

        field.expressionProperties['templateOptions.sum'] = function(_val, _val1, scope) {
          var hidden = false;
          if (scope.options.expressionProperties.hide) {
            hidden = scope.options.expressionProperties.hide(_val, _val1, scope);
          }

          if (!hidden && scope.options.templateOptions.loader) {
            console.log('Running sum');
            $q.when(scope.options.templateOptions.loader())
              .then(function(res) {
                var idx = _.indexOf(_this.fields, field);
                _this.fields[idx] = _this.buildField(res);
              })
              .catch(function(error) {
                $log.warn('Could not construct field', error);
                scope.options.templateOptions.label = 'Could not load field';
              });
          }
        };
      }

      return field;
    };

    Form.prototype.buildOptions = function(categories) {
      var flatten = function flat(categories, parent, sub) {
        var options = [];
        categories = categories || [];
        categories.forEach(function(category) {
          var f = {
            _id: category._id,
            key: category._id,
            name: category.name
          };

          if (parent) {
            f.group = parent.name;
          }

          if (category.categories && category.categories.length && sub) {
            options = options.concat(flatten(category.categories, category, false));
          } else {
            options.push(f);
          }
        });
        return options;
      };

      return flatten(categories, null, true);
    };

    Form.prototype.extend = function(fields, group) {
      var _this = this;
      _.forEach(fields, function(field) {
        _this.addField(field, group);
      });
    };

    Form.prototype.clearFields = function() {
      this.fields = [];
    };

    Form.prototype.extendFromDb = function(fields, group) {
      var _this = this;
      _.forEach(fields, function(field) {
        let fileOptions;
        if (field.doc.fieldType === 'file') {
          const defaultFileOptions = {
            accept: ['all'],
            multiple: false,
            sizeLimit: 100,
            fileLimit: 0,
            storeSingle: false
          };
          fileOptions = {
            accept: field.doc.fileTypes ?? defaultFileOptions.accept,
            sizeLimit: field.doc.sizeLimit ?? defaultFileOptions.sizeLimit,
            multiple: field.doc.multiple ?? defaultFileOptions.multiple,
            fileLimit: field.doc.fileLimit ?? defaultFileOptions.fileLimit
          };
        }
        _this.addField({
          id: field.doc._id,
          type: field.doc.fieldType,
          label: field.doc.name,
          unique: field.doc.isUnique,
          required: field.doc.isRequired,
          disabled: field.doc.disabled,
          fileOptions: fileOptions,
          options: 'categories' in field.doc && field.doc.categories
        }, group);
      });
    };

    Form.disableAll = function(fields) {
      return _.chain(fields)
              .filter(function(field) {
                return !_.isUndefined(field);
              })
              .map(function(field) {
                field.disabled = true;
                return field;
              })
              .value();
    };

    return Form;
  }

  FormsService.$inject = ['$q', '$log', 'moment', 'LocalizationService', 'EMPTY_SIGNATURE'];

  angular.module('widgets.forms')
    .factory('FormsService', FormsService);
})();
