(function() {
  'use strict';

  function ReportViewConfController($scope, Form, Reports, capitalizeFilter) {
    var ctrl = this;

    ctrl.model = $scope.model;
    var options = $scope.options || {};
    var idAttr = options.useVersionId ? 'versionGroupId' : '_id';

    function isMuliReport(reportId, reports) {
      if (!reportId) {
        return false;
      }

      var report = _.find(reports, function(report) {
        return report.doc[idAttr] === reportId;
      });

      if (report === undefined) {
        return false;
      }

      return report.reportType === 'multiReport';
    }

    function loadForm(reports) {
      return new Form(
        [
          {
            id: 'report',
            type: 'discrete',
            label: 'Select a report',
            required: true,
            disabled: options.disabled || false,
            options: _.chain(reports)
              .map(function(report) {
                var name = report.doc.title + ' (v' + report.doc.version + ')';
                return { _id: report.doc[idAttr], name: name };
              })
              .sortBy('name')
              .value()
          },
          {
            id: 'size',
            type: 'discrete',
            label: 'Select default number of rows',
            defaultValue: 10,
            required: true,
            disabled: options.disabled || false,
            controller: ['$scope', function($scope) {
              $scope.config = ctrl.model;
              var options = [10, 20, 25, 50, 100, 500, 1000, 5000];
              $scope.options.templateOptions.options = [];
              $scope.options.templateOptions.options = _.map(options, function(option) {
                return { _id: option, name: option };
              });
            }]
          },
          {
            id: 'display',
            type: 'discrete',
            label: 'Select what to display',
            required: true,
            disabled: options.disabled || false,
            controller: ['$scope', function($scope) {
              $scope.config = ctrl.model;
              $scope.$watchCollection('config.report', function(reportId, oldReportId) {
                if (oldReportId !== reportId) {
                  $scope.model.display = undefined;
                  $scope.model.tableColumns = ['__all__'];
                }

                $scope.options.templateOptions.options = [];
                if (_.isUndefined(reportId) || _.isEmpty(reportId)) {
                  return;
                }

                var report = _.find(reports, function(report) {
                  return report.doc[idAttr] === reportId;
                });

                var options = ['table'];
                if (!_.isUndefined(report) && !_.isUndefined(report.charts)) {
                  options = options.concat(['charts', 'both']);
                } else {
                  $scope.model.display = 'table';
                }

                $scope.options.templateOptions.options = _.map(options, function(item) {
                  return { _id: item, name: capitalizeFilter(item) };
                });
              });
            }],
            expressionProperties: {
              hide: function(_$viewValue, _$modelValue, scope) {
                return _.isEmpty(scope.model.report) || isMuliReport(scope.model.report, reports);
              }
            }
          },
          {
            id: 'charts',
            type: 'discrete_multiple',
            label: 'Select charts to display',
            required: true,
            disabled: options.disabled || false,
            controller: ['$scope', function($scope) {
              $scope.config = ctrl.model;
              $scope.$watchCollection('config.report', function(reportId) {
                $scope.options.templateOptions.options = [];
                if (_.isUndefined(reportId)) {
                  return;
                }

                var report = _.find(reports, function(report) {
                  return report.doc[idAttr] === reportId;
                });

                if (_.isUndefined(report) || _.isUndefined(report.charts)) {
                  return;
                }

                $scope.options.templateOptions.options = _.map(report.charts, function(chart) {
                  return { _id: chart.id, name: chart.name };
                });
              });
            }],
            expressionProperties: {
              hide: function(_$viewValue, _$modelValue, scope) {
                var hide = _.indexOf(['charts', 'both'], scope.model.display) === -1;
                if (hide) {
                  scope.model.charts = [];
                }

                return hide || isMuliReport(scope.model.report, reports);
              }
            }
          },
          {
            id: 'chartsColumns',
            type: 'discrete_multiple',
            label: 'Select charts columns to display',
            required: true,
            disabled: options.disabled || false,
            controller: ['$scope', function($scope) {
              $scope.config = ctrl.model;

              $scope.$watchCollection('config.charts', function(chartId, oldChartId) {
                if (oldChartId !== chartId) {
                  $scope.model.chartsColumns = undefined;
                }
                var options = [{ _id: '__all__', name: 'All columns' }];

                $scope.options.templateOptions.options = options;
                if (_.isUndefined(chartId) || _.isEmpty(chartId)) {
                  return;
                }

                var reportId = ctrl.model.report;
                var report = _.find(reports, function(report) {
                  return report.doc[idAttr] === reportId;
                });

                var charts = _.filter(report.charts, function(chart) {
                  return chartId.indexOf(chart.id) !== -1;
                });

                if (charts.length === 0) {
                  return;
                }

                _.forEach(charts, function(chart) {
                  _.forEach(chart.columns, function(col) {
                    options.push({ _id: col.id, name: capitalizeFilter(col.name) });
                  });
                });

                $scope.options.templateOptions.options = options;
                if (options.length > 0 && ctrl.model.chartsColumns === undefined) {
                  ctrl.model.chartsColumns = ['__all__'];
                }
              });
            }],
            expressionProperties: {
              hide: function(_$viewValue, _$modelValue, scope) {
                return _.isEmpty(scope.model.charts) || isMuliReport(scope.model.report, reports);
              }
            }
          },
          {
            id: 'tableColumns',
            type: 'discrete_multiple',
            label: 'Select table columns to display',
            required: true,
            disabled: options.disabled || false,
            controller: ['$scope', function($scope) {
              $scope.config = ctrl.model;

              $scope.$watchCollection('config.display', function(display, oldDisplay) {
                var preOptions = [
                  { _id: '__all__', name: 'All columns' }
                ];

                if (oldDisplay !== display) {
                  $scope.model.tableColumns = undefined;
                }

                $scope.options.templateOptions.options = preOptions;
                if (_.isUndefined(display) || _.isEmpty(display)) {
                  return;
                }

                var reportId = ctrl.model.report;
                var report = _.find(reports, function(report) {
                  return report.doc[idAttr] === reportId;
                });

                var options = _.map(
                  report.outputFields, function(outputField, index) {
                    return { _id: index, name: capitalizeFilter(outputField.name) };
                  });

                $scope.options.templateOptions.options = preOptions.concat(options);

                if ($scope.options.templateOptions.options.length > 0 &&
                  ctrl.model.tableColumns === undefined) {
                  ctrl.model.tableColumns = ['__all__'];
                }
              });
            }],
            expressionProperties: {
              hide: function(_$viewValue, _$modelValue, scope) {
                return _.isEmpty(scope.model.display) ||
                      scope.model.display === 'charts' ||
                      isMuliReport(scope.model.report, reports);
              }
            }
          }
        ]
      );
    }

    Reports.findLatestPublished('info')
      .then(function(reportVersions) {
        if (idAttr === '_id' && ctrl.model.report) {
          var found = _.some(reportVersions, function(item) {
            return item.doc._id === ctrl.model.report;
          });

          if (!found) {
            return Reports.find(ctrl.model.report)
              .then(function(report) {
                reportVersions.push({ doc: report });
                return reportVersions;
              })
              .catch(function(err) {
                console.log('Could not found report', err);
                return reportVersions;
                // But ignore as we don't want to cry too much here
              });
          }
        }
        return reportVersions;
      })
      .then(function(reportVersions) {
        ctrl.form = loadForm(reportVersions);
      })
      .catch(function(error) {
        if (error.status_code === 403) {
          ctrl.error = 'You don\'t have the permission to view reports';
        } else {
          ctrl.error = error;
        }
      });
  }

  ReportViewConfController.$inject = ['$scope', 'FormsService', 'ReportTemplatesService',
    'capitalizeFilter'];

  function ReportViewConfDirective() {
    return {
      scope: {
        model: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/reports/directives/report-view-conf.html',
      replace: true,
      controller: ReportViewConfController,
      controllerAs: 'viewConfCtrl'
    };
  }

  angular.module('component.reports')
    .directive('kzReportConfView', ReportViewConfDirective);
})();
