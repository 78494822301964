(function() {
  'use strict';

  function SlotController($scope, $state, UserFields, USER_STATES) {
    var ctrl = this;

    var updateState = function(action) {
      $scope.item.updateState(action)
        .then(function() {
          $scope.options.loadList();
        });
    };

    ctrl.STATES = USER_STATES;

    // get all user fields
    UserFields.findFeatured()
      .then(function(featuredUserFields) {
        ctrl.featuredUserFields = featuredUserFields;
      });

    ctrl.mainHref = (function() {
      // ALways return the summary page
      var href = $state.href('dashboard.summary', { user: $scope.item.doc.username });
      return href;
    })();

    ctrl.actionButtons = $scope.item.actionButtons();
    ctrl.secondaryActionButtons = $scope.item.secondaryActionButtons().concat([
      {
        label: 'Disable',
        icon: 'icon-toggle-off',
        onClick: updateState.bind(this, 'user_disable'),
        showCondition: function() {
          if ($scope.item.isDisabled($scope.item)) {
            return false;
          }
          return $scope.item.canReview();
        },
        klass: 'text-danger'
      },
      {
        label: 'Enable',
        icon: 'icon-toggle-on',
        onClick: updateState.bind(this, 'user_enable'),
        showCondition: function() {
          if (!$scope.item.isDisabled) {
            return false;
          }
          return $scope.item.canReview();
        },
        klass: 'text-success'
      }
    ]);
  }

  SlotController.$inject = [
    '$scope', '$state', 'UserFieldsService', 'USER_STATES'
  ];

  function SlotDirective() {
    return {
      scope: {
        item: '=',
        options: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/user.slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.users')
    .directive('userSlot', SlotDirective)
    .controller('UserSlotController', SlotController);
})();
