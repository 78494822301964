(function() {
  'use strict';

  function DashboardReorderController(
    $state,
    DashboardTemplates,
    Utils,
    Notify
  ) {
    var ctrl = this;
    ctrl.loaded = false;

    Utils.setPageTitle('Reorder Dashboard');

    var getPrevious;
    var getNext;

    DashboardTemplates.findAll()
      .then(function(templates) {
        ctrl.templates = _.sortBy(templates, 'order');

        ctrl.loaded = true;
      });

    ctrl.actions = [
      {
        label: 'Done',
        icon: 'icon-save',
        href: (function() { return $state.href('epf.dashboard-templates.index'); })(),
        klass: 'btn-success'
      }
    ];

    getPrevious = function(c, l) {
      return c === 0 ? c : l[c - 1].order;
    };

    getNext = function(c, l) {
      return c === l.length - 1 ? l[c].order + 1 : l[c + 1].order;
    };

    ctrl.reorder = function(evt) {
      var current = _.findIndex(evt.models,
        function(m) { return m._id === evt.model._id; }),
          prev = getPrevious(current, evt.models),
          next = getNext(current, evt.models),
          order = Utils.getNewOrderIndex(prev, next);

      DashboardTemplates.updateOrder(evt.model._id, order)
        .then(function(data) {
          evt.model._rev = data.rev;
          evt.model.order = order;
          Notify.success('Order successfully updated!', 'Success');
        })
        .catch(Utils.showError);
    };
  }

  DashboardReorderController.$inject = [
    '$state',
    'DashboardTemplatesService',
    'UtilsService',
    'NotifyService'
  ];

  angular.module('component.dashboardTemplates')
    .controller('DashboardReorderController', DashboardReorderController);
})();

