(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider

      .state('epf.bulk-actions', {
        url: 'bulk-actions',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }],

          access: [
            '$q', 'BulkActionsService',
            function($q, BulkActionsService) {
              return BulkActionsService.getAvailableBulkActions()
                .then(function(availableBulkActions) {
                  if (_.isEmpty(availableBulkActions)) {
                    var msg = 'You are not allowed to view bulk action area';
                    return $q.reject({ status: 403, message: msg });
                  }
                });
            }]
        }
      })
      .state('epf.bulk-actions.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/bulk-actions/bulk-actions.html',
            controller: 'BulkActionsController',
            controllerAs: 'bulkActionsCtrl'
          }
        }
      })
      .state('epf.bulk-actions.new', {
        url: '/new',
        views: {
          content: {
            templateUrl: 'app/components/bulk-actions/bulk-action-create-or-edit.html',
            controller: 'BulkActionCreateOrEditController',
            controllerAs: 'bulkActionCtrl'
          }
        }
      })
      .state('epf.bulk-actions.edit', {
        url: '/edit/:id',
        views: {
          content: {
            templateUrl: 'app/components/bulk-actions/bulk-action-create-or-edit.html',
            controller: 'BulkActionCreateOrEditController',
            controllerAs: 'bulkActionCtrl'
          }
        }
      })
      .state('epf.bulk-actions.view', {
        url: '/view/:id',
        views: {
          content: {
            templateUrl: 'app/components/bulk-actions/bulk-action-view.html',
            controller: 'BulkActionViewController',
            controllerAs: 'bulkActionViewCtrl'
          }
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.bulkActions')
    .config(config);
})();
