(function() {
  'use strict';

  angular.module('component.bulkActions')
    .directive('columnsMappingInfoCard', function() {
      return {
        restrict: 'A',
        template: '<p>In order to ensure proper import of your data, please make sure to double ' +
          'check the column matching for any inconsistencies</p>'
      };
    });
})();
