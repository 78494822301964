(function() {
  'use strict';

  function BulkActionCreateOrEditController(
    $q, $stateParams, AsyncTasks, BulkActionsService, Form, Utils
  ) {
    var ctrl = this;

    ctrl.bulkActionId = $stateParams.id;
    if (_.isUndefined($stateParams.id)) {
      Utils.setPageTitle('New bulk action');
      BulkActionsService.getAvailableBulkActions()
        .then(function(actions) {
          ctrl.model = {
            taskAction: actions.length > 0 ? actions[0].id : null
          };
          ctrl.readableAction = actions.length > 0 ? actions[0].name : '';
          ctrl.taskActionChooserForm = new Form([
            {
              id: 'taskAction',
              type: 'discrete',
              label: 'What action would you like to perform?',
              required: true,
              options: _.map(actions, function(action) {
                return { _id: action.id, name: action.name };
              })
            }
          ]);
        });
    } else {
      AsyncTasks.find($stateParams.id, { cached: false })
        .then(function(bulkActionDoc) {
          if (bulkActionDoc.action !== 'import_file') {
            return $q.reject({ status: 405, message: 'This bulk action cannot be edited' });
          }

          var importDoc = bulkActionDoc;

          Utils.setPageTitle('Edit bulk action task: ' + importDoc.data.name);
          if (_.indexOf(['new', 'processing'], importDoc.state) > -1) {
            var msg = 'You cannot restart a new import of a running or completed task';
            return $q.reject(
              { status: 405, message: msg }
            );
          }

          ctrl.name = importDoc.data.name;
          var data = importDoc.data;
          data.taskAction = importDoc.action;

          // deserialize (a bit ugly :()
          if (data.extra) {
            data.eventTypeId = data.extra.event_type_id;
            if (data.extra.origin) {
              data.originName = data.extra.origin.name;
              data.originId = data.extra.origin.id;
              delete data.extra;
            }
          }
          ctrl.model = data;
        })
        .catch(Utils.showError);
    }
  }

  BulkActionCreateOrEditController.$inject = [
    '$q',
    '$stateParams',
    'AsyncTasksService',
    'BulkActionsService',
    'FormsService',
    'UtilsService'
  ];

  angular.module('component.bulkActions')
    .controller('BulkActionCreateOrEditController', BulkActionCreateOrEditController);
})();
