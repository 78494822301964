(function() {
  'use strict';

  function Card($rootScope) {
    return {
      restrict: 'A',
      template: '<results-overview meta="meta" ng-if="meta"></results-overview>',
      link: function($scope) {
        $rootScope.$on('BulkActionViewLoaded', function(_evt, args) {
          $scope.meta = args.meta;
        });
      }
    };
  }

  Card.$inject = ['$rootScope'];

  angular.module('component.bulkActions')
    .directive('resultsOverviewCard', Card);
})();
