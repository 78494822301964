(function() {
  'use strict';

  function AsyncTasksService($q, $timeout, EasApiStore, EasApi) {
    var service = new EasApiStore('async-tasks');
    var apiVersion = '';


    service.triggerUpdate = undefined;

    service.status = {
      pendingCount: 0
    };

    service.search = function(data, options) {
      var opts = _.extend({ version: this.apiVersion }, options);
      var url = service.listUrl() + 'search';
      return EasApi.post(url, data, {}, opts);
    };

    service.start = function(taskAction, params, files, options) {
      options = options || {};
      var url = service.listUrl() + 'start/' + taskAction;
      if (options.isMultipart) {
        return EasApi.postMultiPart(url, params, {}, files, { version: apiVersion });
      }
      return EasApi.post(url, params, {}, { version: apiVersion });
    };

    service.restart = function(taskId, params, files, options) {
      options = options || {};
      var url = service.listUrl() + 'restart/' + taskId;
      if (options.isMultipart) {
        return EasApi.postMultiPart(url, params, {}, files, { version: apiVersion });
      }
      return EasApi.post(url, params, {}, { version: apiVersion });
    };

    service.abort = function(taskId) {
      var url = service.listUrl() + 'abort/' + taskId;
      return EasApi.put(url, {}, {}, { version: apiVersion });
    };

    service.downloadOutput = function(taskId) {
      var url = service.listUrl() + taskId + '/download';
      return EasApi.get(url, {}, {}, { version: apiVersion });
    };

    service.findWithOutput = function(taskId, outputOptions) {
      outputOptions = _.assignIn({
        includeOutputSummary: true,
        includeOutput: true
      }, outputOptions || {});
      var url = this.specificUrl(taskId);
      var opts = { version: this.apiVersion };
      return EasApi.get(url, undefined, outputOptions, opts)
        .then(function(itm) {
          return itm;
        });
    };

    service.summary = function(user) {
      var url = service.listUrl() + 'summary/user/';
      if (user !== undefined) {
        url += user;
      }

      return EasApi.get(url, {}, {}, { noCache: true, version: apiVersion });
    };

    service.summaryForAction = function(action) {
      var url = service.listUrl() + 'summary/action/ ' + action;
      return EasApi.get(url, {}, {}, { noCache: true, version: apiVersion });
    };

    service.pendingTaskCount = function(_user) {
      return $q.when({});
      // return service.summary(user)
      //   .then(function(data) {
      //     var totalPending = 0;
      //     _.forEach(data, function(value) {
      //       totalPending += value.states.pending || 0;
      //     });

      //     var result = {
      //       totalPending: totalPending,
      //       perAction: data
      //     };
      //     return result;
      //   });
    };

    // Importer
    service.importerDownloadTemplate = function(docType, extra) {
      var url = service.listUrl() + 'importer/download-template/' + docType + '/' + extra;
      return EasApi.downloadBlob(url, {}, {}, { version: apiVersion });
    };

    service.importerKzFields = function(data) {
      var url = service.listUrl() + 'importer/kz-fields';
      return EasApi.post(url, data, {}, { version: apiVersion });
    };

    service.importerPreviewItem = function(taskId, itemPosition) {
      var url = service.listUrl() + 'importer/preview-item/' + taskId + '/' + itemPosition;
      return EasApi.get(url, {}, {}, { version: apiVersion });
    };

    service.importerDownloadOutput = function(taskId) {
      var url = service.listUrl() + 'importer/' + taskId + '/download';
      return EasApi.downloadBlob(url, {}, {}, { version: apiVersion });
    };

    var updator = function() {
      service.timeoutHandler = undefined;
      service.pendingTaskCount().then(function(result) {
        service.status = result;
      })
      .catch(function(err) {
        console.log('Failed updating pending task count', err);
      })
      .finally(function() {
        if (service.status.totalPending > 0) {
          console.log('Starting pending task checker in 6s');
          service.timeoutHandler = $timeout(updator, 6000);
        } else {
          console.log('Starting pending task checker in 10m');
          service.timeoutHandler = $timeout(updator, 600000);
        }
      });
    };

    service.startUpdator = function() {
      $timeout(function() {
        service.stopUpdator();
        service.triggerUpdate = {};
        updator();
      }, 0);
    };
    // service.startUpdator();

    service.stopUpdator = function() {
      if (service.timeoutHandler) {
        $timeout.cancel(service.timeoutHandler);
        service.timeoutHandler = undefined;
      }
    };

    return service;
  }

  AsyncTasksService.$inject = ['$q', '$timeout', 'EasApiStore', 'EasApiService'];

  angular.module('blocks.asyncTasks')
    .factory('AsyncTasksService', AsyncTasksService);
})();
