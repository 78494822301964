(function() {
  'use strict';

  const SYSTEM_USER_FIELDS = [
    { _id: 'user', key: 'user', name: 'User ID' },
    { _id: 'email', key: 'email', name: 'Email' }
  ];

  function TurnitinControlPanelComponent($uibModal, UserFields, Features, Turnitin, Form, Notify) {
    var ctrl = this;

    ctrl.$onInit = function() {
      Features.isEnabled('turnitin')
        .then(function(has) {
          ctrl.turnitinEnabled = has;
          if (has) {
            return ctrl.setup();
          }
        });
    };

    ctrl.setup = function() {
      console.log('Organisation', ctrl.org._id);
      return Turnitin.getSettings()
        .then(function(settings) {
          ctrl.settings = settings;
          var userIDSource = settings.user_id_source || 'user';
          var opt = _.find(SYSTEM_USER_FIELDS, { _id: userIDSource });
          if (opt !== undefined) {
            return opt;
          }
          return UserFields.find(userIDSource);
        })
        .then(function(uf) {
          ctrl.userID = uf;
        });
    };

    ctrl.$onDestroy = function() {
    };

    ctrl.updateServer = function() {
      var form = new Form([
        {
          id: 'server',
          type: 'string',
          required: true,
          label: 'Turnitin URL',
          helpText: 'Your institution’s unique Turnitin URL. Takes the format of ' +
                    'https://[YourInstitution].turnitin.com. For example. ' +
                    'https://universityofturnitin.turnitin.com'
        },
        {
          id: 'secret_key',
          type: 'string',
          required: true,
          label: 'Turnitin API Key',
          helpText: 'The unique API key generated when configuring the integration in Turnitin.'
        }
      ]);

      var popup = $uibModal.open({
        animation: true,
        size: 'md',
        component: 'formPopupComponent',
        resolve: {
          options: function() {
            return {
              title: 'Turnitin URL settings',
              okbutton: 'Update'
            };
          },
          form: function() {
            return form;
          },
          model: function() {
            return {
              server: ctrl.settings.server
            };
          }
        }
      }).result;

      popup.then(function(result) {
        Turnitin.postSettings(result)
          .then(function() {
            ctrl.setup();
            Notify.success('Settings has been updated');
          })
          .catch(function(err) {
            console.log(err);
            Notify.error('Failed updating settings');
          });
      }).catch(function(err) {
        // This is most likely cancel
        console.log(err);
      });
    };

    ctrl.updateUserIDSource = function() {
      UserFields.findAll()
        .then(function(ufs) {
          var options = _.chain(ufs)
            .filter(function(uf) {
              return uf.doc.isUnique;
            })
            .map(function(uf) {
              return {
                _id: uf.doc._id,
                key: uf.doc._id,
                name: uf.doc.name
              };
            })
            .value();

          options = [].concat(SYSTEM_USER_FIELDS, options);

          var form = new Form([
            {
              id: 'user_id_source',
              type: 'discrete',
              required: true,
              label: 'User ID Source',
              helpText: 'Pick which part of a user profile should be used as an ID in Turnitin',
              options: options
            }
          ]);

          var popup = $uibModal.open({
            animation: true,
            size: 'md',
            component: 'formPopupComponent',
            resolve: {
              options: function() {
                return {
                  title: 'Turnitin User ID selector',
                  okbutton: 'Update'
                };
              },
              form: function() {
                return form;
              },
              model: function() {
                return {
                  user_id_source: ctrl.userID._id
                };
              }
            }
          }).result;
          return popup;
        })
        .then(function(result) {
          Turnitin.postSettings(result)
            .then(function() {
              ctrl.setup();
              Notify.success('Settings has been updated');
            })
            .catch(function(err) {
              console.log(err);
              Notify.error('Failed updating settings');
            });
        }).catch(function(err) {
          // This is most likely cancel
          console.log(err);
        });
    };

    ctrl.registerHook = function() {
      var form = new Form([
        {
          id: 'webhook_url',
          type: 'string',
          required: true,
          label: 'Webhook receiver URL'
        }
      ]);

      var popup = $uibModal.open({
        animation: true,
        size: 'md',
        component: 'formPopupComponent',
        resolve: {
          options: function() {
            return {
              title: 'Register webhook',
              okbutton: 'Register'
            };
          },
          form: function() {
            return form;
          },
          model: function() {
            return {
              webhook_url: Turnitin.getWebhookUrl()
            };
          }
        }
      }).result;

      popup.then(function(result) {
        Turnitin.registerWebhook(result)
          .then(function() {
            ctrl.setup();

            Notify.success('Settings has been updated');
          })
          .catch(function(err) {
            console.log(err);
            Notify.error('Failed updating settings');
          });
      }).catch(function(err) {
        // This is most likely cancel
        console.log(err);
      });
    };
  }

  TurnitinControlPanelComponent.$inject = [
    '$uibModal',
    'UserFieldsService',
    'FeaturesService',
    'TurnitinService',
    'FormsService',
    'NotifyService'
  ];

  angular.module('component.organisations')
    .component('turnitinControlPanel', {
      templateUrl: 'app/components/organisations/turnitin.controlpanel.component.html',
      controller: TurnitinControlPanelComponent,
      bindings: {
        org: '<'
      }
    });
})();
