angular.module('component.bulkActions')
  .component(
    'resultsOverview', {
      templateUrl: 'app/components/bulk-actions/components/results-overview.html',
      controller: function() {
        var ctrl = this;

        function update(meta) {
          if (_.isUndefined(meta) || _.isEmpty(meta) || !meta) {
            return;
          }

          ctrl.readableMeta = {
            'Records created': meta.created,
            'Records deleted': meta.deleted,
            Errors: meta.errors,
            'Existing records': meta.exists || meta.updated,
            'Existing records updated': meta.updated,
            'Existing records not updated': meta.no_changes,
            'Total processed': meta.processed
          };
        }

        this.$onInit = function() {
          update(ctrl.meta);
        };

        this.$onChanges = function(changes) {
          update(changes.meta.currentValue);
        };
      },
      bindings: {
        meta: '<'
      }
    });
