import { downgradeComponent } from '@angular/upgrade/static';
import { OrganisationSettingsComponent } from '@fry/labs/organisation.settings.component';
import { ProtectedSettingsComponent } from '@fry/labs/protected.settings.component';
import { LabsSettingsComponent } from 'src/ang2/app/labs/labs.settings.component';

(function() {
  'use strict';

  function config($stateProvider) {
    $stateProvider
      .state('epf.settings', {
        url: 'settings/',
        abstract: true,
        views: {
          main: {
            template: '<div data-ui-view="content"></div>'
          }
        },
        resolve: {
          setup: ['SetupService', function(Setup) {
            return Setup.setupApp();
          }]
        }
      })

      .state('epf.settings.index', {
        url: '',
        views: {
          content: {
            templateUrl: 'app/components/settings/form.html',
            controller: 'SettingsController',
            controllerAs: 'settingsCtrl'
          }
        }
      })

      .state('epf.settings.review', {
        url: 'review',
        views: {
          content: {
            templateUrl: 'app/components/settings/review.html',
            controller: 'ReviewController',
            controllerAs: 'reviewCtrl'
          }
        }
      })

      .state('epf.settings.status', {
        url: 'status',
        views: {
          content: {
            templateUrl: 'app/components/settings/status.html',
            controller: 'StatusController',
            controllerAs: 'statusCtrl'
          }
        }
      })

      .state('epf.settings.offlinesettings', {
        url: 'offline',
        params: {
          showAdvanced: null
        },
        views: {
          content: {
            templateUrl: 'app/components/settings/offlinesettings.html',
            controller: 'OfflineSettingsController',
            controllerAs: 'offlineCtrl'
          }
        }
      })

      .state('epf.settings.offlinetest', {
        url: 'test',
        views: {
          content: {
            templateUrl: 'app/components/settings/offlinetest.html',
            controller: 'OfflineTestController',
            controllerAs: 'testCtrl'
          }
        }
      })
      .state('epf.settings.labs', {
        url: 'labs',
        views: {
          content: {
            component: 'easLabs'
          }
        },
        resolve: {
          setup: ['$q', 'OrganisationService', function($q, OrganisationService) {
            if (!OrganisationService.currentOrganisation().enabledLabs) {
              return $q.reject({ status: 403, message: 'Feature not enabled' });
            }
          }]
        }
      })
      .state('epf.settings.organisation', {
        url: 'organisation',
        views: {
          content: {
            component: 'easOrganisation'
          }
        }
      })
      .state('epf.settings.protected', {
        url: 'protected',
        views: {
          content: {
            component: 'easProtected'
          }
        },
        resolve: {
          setup: ['SecurityService', function(Security) {
            return Security.hasRole('system:superadmin', { promise: true });
          }]
        }
      });
  }

  config.$inject = ['$stateProvider'];

  angular.module('component.settings')
    .directive('easLabs', downgradeComponent({ component: LabsSettingsComponent }))
    .directive('easOrganisation', downgradeComponent({ component: OrganisationSettingsComponent }))
    .directive('easProtected', downgradeComponent({ component: ProtectedSettingsComponent }))
    .config(config);
})();
