(function() {
  'use strict';

  function AutoSaveService($log, $q, $rootScope, $location,
    Drafts, Features, Utils, Auth, Notify, AUTOSAVE_EVENTS) {
    var service = {};

    /**
     * Use this to get initial template for a info object
     *
     * Info object keeps information about current autosave
     * and its state
     */
    service.getAutoSaveInfo = function(options) {
      options = options || {};
      return _.assignIn({}, {
        id: undefined,
        state: 'none'
      }, options);
    };

    /**
     * Return an inital autosave id
     *
     * This is used find out whether we should load autosave
     * or the real object.
     *
     * At the moment it is using only information from URL
     * @return {[type]} [description]
     */
    service.getInitialAutoSaveId = function() {
      return $location.search().autosave;
    };

    service.isEnabled = function(info) {
      return info.enabled;
    };

    service.getEnabled = function() {
      return Features.isEnabled('autosave')
        .then(function(active) {
          return active && !Auth.isLoggedInBy();
        })
        .catch(function() {
          return false;
        });
    };

    /**
     * Setup autosave
     *
     * This is used to handle loading of possible autosave. It should
     * be called after every object load in controllers
     *
     * @param  {[type]} info  The autosave info
     * @param  {[type]} model The model to watch on
     * @return {[type]}       Just a promise with no value
     */
    service.setupAutoSave = function(info) {
      // Set the autosave ID from url if there
      info.id = service.getInitialAutoSaveId();
      info.model = info.getModel();

      var promise = service.getEnabled()
        .then(function(enabled) {
          info.enabled = enabled;
          if (enabled) {
            return Drafts.findAllForId(info.model._id)
              .then(function(res) {
                var main;
                if (info.id) {
                  main = _.find(res, function(item) {
                    return item._id === info.id;
                  });
                }

                if (main) {
                  info.id = main._id;
                  return service.loadAutoSave(info, main);
                }
              });
          }

          // If not enabled we force it to no autosave even if found
          service.setID(info, undefined);
        });

      return promise
        .then(function(data) {
          $rootScope.$broadcast(AUTOSAVE_EVENTS.saveInfoUpdate);
          return data;
        });
    };

    service.updateLocation = function(info, key, val) {
      if (!info.noUpdateLocation) {
        $location.search(key, val);
      }
    };

    /**
     * A helper function to make sure the autosave id is persisted in URL
     *
     * @param {[type]} info [description]
     * @param {[type]} id   [description]
     */
    service.setID = function(info, id) {
      info.id = id;
      service.updateLocation(info, 'autosave', id);
    };

    /**
     * Return a final object with autosave object applied
     *
     * This is used for preview and for any display information
     * as autosave object does not contain all the data
     *
     * @param  {[type]} info  Autosave info
     * @param  {[type]} asdoc Autosave object to apply onto real object
     * @return {[type]}       A clone of the real object with the autosave applied
     */
    service.getAutoSaved = function(info, asdoc) {
      if (_.isFunction(info.applyAutosave)) {
        return info.applyAutosave(info.model, asdoc);
      }
      return $q.when(asdoc);
    };

    service.getObject = function(info) {
      return info.fetchModel();
    };

    /**
     * Load autosave into the object being autosaved
     *
     * This is used when loading an autosave. It does not return a clone,
     * it updates the object so that it can be used in forms
     *
     * @param  {[type]} info  Autosave info
     * @param  {[type]} asdoc Autosave object to load
     * @return {[type]}       Just a promise, no value
     */
    service.loadAutoSave = function(info, asdoc) {
      info.asdoc = asdoc;
      if (_.isFunction(info.toObject)) {
        info.toObject(info.model, asdoc.data);
      }
      return $q.when();
    };

    /**
     * Return an object to attach when saving to either autosave or real object

     * @param  {[type]} type Type of save: autosave|manual
     * @return {[type]}      Object with save information
     */
    service.createSaveInfo = function(type) {
      return {
        type: type,
        lastSaved: Utils.now(),
        agent: navigator.userAgent
      };
    };

    /**
     * Return saveInfo from current info
     *
     * This decides whether it should take it from the object or autosave
     * @param  {[type]} info Autosave info
     * @return {[type]}      Object with save information
     */
    service.getSaveInfo = function(info) {
      if (info.model === undefined) {
        return {};
      }

      if (info.id) {
        if (info.asdoc === undefined) {
          return {};
        }

        return info.asdoc.saveInfo || {};
      }

      return info.getSaveInfo(info.model) || {};
    };

    /**
     * Helper function to create a new autosave object from model
     *
     * This uses toAutoSave to convert the model into autosave data
     *
     * @param  {[type]} info Autosave info
     * @param  {[type]} doc  The model to save
     * @return {[type]}      Result of save
     */
    service._createAutoSave = function(info, model, options) {
      options = options || {};
      $log.debug('Creating a new autosave', info.id);
      var asdoc = _.assignIn({}, Drafts.init(info.getMeta(model)), {
        _id: Utils.guid(),
        data: info.toAutoSave(model)
      });

      if (options.preserveSaveInfo) {
        asdoc.saveInfo = info.getSaveInfo(model);
      } else {
        asdoc.saveInfo = service.createSaveInfo('autosave');
      }

      return Drafts.save(asdoc)
        .then(function(as) {
          asdoc._id = as.id;
          asdoc._rev = as.rev;
          info.asdoc = asdoc;
          return as;
        });
    };


    /**
     * Helper function to create an autosave and set as a current to continue
     * autosave on this object
     *
     * @param  {[type]} info Autosave info
     * @param  {[type]} doc  The model to save
     * @return {[type]}      Result of save
     */
    service._createAndSetAutoSave = function(info, doc) {
      return service._createAutoSave(info, doc)
        .then(function(res) {
          service.setID(info, res.id);
          info.state = 'autosave';
          $rootScope.$broadcast(AUTOSAVE_EVENTS.newAutosave, { id: doc._id });
          return { action: 'autosaveCreated', autosave: res.id };
        });
    };

    /**
     * Helper function to update an autosave object from model
     *
     * This uses toAutoSave to convert the model into autosave data
     *
     * @param  {[type]} info Autosave info
     * @param  {[type]} doc  The model to save
     * @return {[type]}      Result of save
     */
    service._saveAutoSave = function(info, doc) {
      // return Drafts.find(info.id)
      return $q.when()
          .then(function() {
            var asdoc = info.asdoc;
            asdoc.data = info.toAutoSave(doc);
            asdoc.saveInfo = service.createSaveInfo('autosave');
            return Drafts.save(asdoc)
              .then(function(res) {
                asdoc._rev = res.rev;
                info.asdoc = asdoc;
                return res;
              });
          })
          .catch(function(err) {
            // If autosave disappeared, create a new one
            if (err && err.status === 404) {
              return service._createAndSetAutoSave(info, doc);
            }

            // If autosave has a conflict, create a new one
            if (err && err.status === 409) {
              return service._createAndSetAutoSave(info, doc);
            }

            return $q.reject(err);
          })
          .then(function(res) {
            info.state = 'autosave';
            return { action: 'autosaveUpdated', autosave: res._id };
          });
    };


    /**
     * Helper function to save the real object
     *
     * @param  {[type]} info Autosave info
     * @param  {[type]} doc  The model to save
     * @return {[type]}      Result of save
     */
    service._saveObject = function(info, doc, saveInfo) {
      doc = doc || info.model;
      saveInfo = saveInfo || service.createSaveInfo('autosave');
      return info.saveObject(doc, saveInfo)
        .then(function(result) {
          if (result.action === 'objectCreated') {
            service.updateLocation(info, 'doc', doc._id);
          }

          info.state = 'normal';
          return result;
        });
    };

    /**
     * Helper function to broadcast that autosave happened
     *
     * @param  {[type]} info Autosave info
     * @param  {[type]} doc  The model to save
     * @return {[type]}      Result of save
     */
    service.broadcastSaved = function(info, result) {
      var res = _.assignIn({
        id: info.model._id,
        lastSaved: Utils.now()
      }, result || {});
      $rootScope.$broadcast(AUTOSAVE_EVENTS.saved, res);
    };

    /**
     * Perform an autosave save
     *
     * This decides whether to store on object or autosave
     *
     * @param  {[type]} info Autosave info
     * @param  {[type]} doc  The model to save
     * @return {[type]}      Result of save
     */
    service.save = function(info) {
      if (!service.isEnabled(info)) {
        return $q.when();
      }

      var doc = info.model;
      // If there is autosave if, store on autosave object
      if (!info.shouldSave) {
        return $q.when({ action: 'noaction' });
      }

      // Lets mark it false here in case
      info.shouldSave = false;
      var promise;

      if (info.isNew && (info.state === undefined || info.state === 'none')) {
        promise = service._saveObject(info, doc);
      } else if (info.id) {
        $log.debug('Storing on autosave', info.id);
        promise = service._saveAutoSave(info, doc);
      } else if (_.isFunction(info.saveObject)) {
        $log.debug('Storing on object');
        promise = service._createAndSetAutoSave(info, doc)
          .catch(function(err) {
            if (err && err.status === 409) {
              return service._createAndSetAutoSave(info, doc);
            }
            return $q.reject(err);
          });
      } else {
        $log.debug('Will create an autosave as object saving is not defined');
        promise = service._createAndSetAutoSave(info, doc);
      }

      return promise
        .then(function(res) {
          var result = _.assignIn({
            id: doc._id,
            lastSaved: Utils.now()
          }, res || {});
          // Notify.success(result.action);
          service.broadcastSaved(info, result);
          return result;
        })
        .catch(function(err) {
          console.log('Failed autosave', err);
          // Lets mark it again as unsaved so that it will be retried
          info.shouldSave = true;
        });
    };

    /**
     * Create a new autosave from the real object
     *
     * Usually because we want to override it with an autosave
     * but want to preserve the changes
     *
     * @param  {[type]} info Autosave info
     * @return {[type]}      Result of the drafts save action
     */
    service.objectToAutosave = function(info, options) {
      //  1. Load the real object
      return info.fetchModel()

        //  2. Create an autosave from the real object
        .then(function(model) {
          return info.validate(model);
        })
        .then(function(model) {
          return service._createAutoSave(info, model, options);
        });
    };

    /**
     * Switches to a specific autosave
     *
     * We expect it is no longer possible to save onto the real object
     * because we have an autosave. Effectively it means swap real object with
     * the autosaved one.
     *
     * 0. Validate we can override the real object
     * 1. Preserve current real object as an autosave
     * 2. Load an autosave so that it becomes the real object
     * 3. Remove the autosave as it's already on real object
     *
     * @param  {[type]} info  Autosave info
     * @param  {[type]} asdoc The autosave to swtich to
     * @return {[type]}       No value
     */
    service.switchToAutosave = function(info, asdoc) {
      // Make sure we have everything saved
      if (asdoc._id === info.id) {
        return $q.when();
      }

      // If we are on an autosave, lets save it and change pointer
      var promise = service.save(info)
      .then(function() {
        info.asdoc = asdoc;
        service.setID(info, asdoc._id);
        info.toObject(info.model, asdoc.data);
      })

      .then(function() {
        $rootScope.$broadcast(AUTOSAVE_EVENTS.newAutosave, { id: info.model._id });
        Notify.success('Switched to selected revision');
      })

      .catch(function(err) {
        Notify.error('Could not switch to selected revision');
        return $q.reject(err);
      });

      return promise;
    };

    service.switchToObject = function(info) {
      if (_.isUndefined(info.id)) {
        return $q.when();
      }

      return service.save(info)
        .then(function() {
          return info.fetchModel();
        })
        .then(function(model) {
          info.toObject(info.model, model);
          service.setID(info, undefined);
          $rootScope.$broadcast(AUTOSAVE_EVENTS.newAutosave, { id: info.model._id });
        });
    };

    /**
     * Create an autosave from real object
     *
     * This may be redundant
     *
     * @param  {[type]} info Autosave info
     * @return {[type]}      Result of autosave being saved
     */
    service.preserveCurrentObject = function(info, options) {
      // We are on autosave, create new autosave from real object
      // to preserve it and normally override the draft
      return service.objectToAutosave(info, options);
    };

    /**
     * Preserves real object and steal the _rev so that we can override it
     *
     * This has to validate we can override
     *
     * @param  {[type]} info Autosave info
     * @return {[type]}      [description]
     */
    service.preserveAndOverride = function(info) {
      return service.preserveCurrentObject(info, { preserveSaveInfo: true })
        .then(function() {
          return info.fetchModel();
        })
        .then(function(model) {
          info.override(info.model, model);
        });
    };

    /**
     * Manual save the object onto the real one
     *
     * This always saves onto the real object. If an autosave is manually saved
     * it preserves the current object, overrides and saves
     *
     * @param  {[type]} info Autosave info
     * @return {[type]}      No value
     */
    service.manualSave = function(info) {
      // If we are on section, lets save it
      if (!service.isEnabled(info)) {
        return info.saveObject(info.model);
      }
      var promise;
      var saveInfo = service.createSaveInfo('manual');

      var currentAutoSave = info.id;
      promise = service._saveObject(info, undefined, saveInfo)
        .catch(function(err) {
          if (err && err.status === 409) {
            return service.preserveAndOverride(info)
              .then(function() {
                return service._saveObject(info, undefined, saveInfo);
              });
          }
          return $q.reject(err);
        })
        .then(function() {
          if (currentAutoSave) {
            return Drafts.remove(currentAutoSave)
              .catch(function(err) {
                // We do not mind as it is just an autosave
                console.log(err);
              });
          }
        });

      return promise.then(function() {
        info.isNew = false;
        info.shouldSave = false;
        service.setID(info, undefined);
        $rootScope.$broadcast(AUTOSAVE_EVENTS.newAutosave, { id: info.model._id });
        service.broadcastSaved(info);
      });
    };

    /**
     * Remove the object no matter of other autosaves
     *
     * @param  {[type]} info Autosave info
     * @return {[type]}      Result of object removing
     */
    service.removeObject = function(info) {
      return info.removeObject(info.model)
        .catch(function(err) {
          if (err && err.status === 404) {
            return;
          }
          return $q.reject(err);
        })
        .finally(function() {
          info.shouldSave = false;
        });
    };

    /**
     * Remove given autosave
     *
     * @param  {[type]} info Autosave info
     * @param  {[type]} id   Autosave id to remove
     * @return {[type]}      Result of draft removing
     */
    service.removeAutoSave = function(info, id) {
      id = id || info.id;
      return Drafts.remove(id)
        .catch(function(err) {
          if (err && err.status === 404) {
            return;
          }
          return $q.reject(err);
        })
        .finally(function() {
          info.shouldSave = false;
        });
    };

    /**
     * Handle the situation when user cancels the operation
     *
     * This should handle all the cases depending on the state
     *
     * @param  {[type]} info Autosave info
     * @return {[type]}      Any result
     */
    service.handleCancel = function(info) {
      if (!service.isEnabled(info)) {
        return $q.when();
      }

      var promise;
      // Skip if we are before first save
      if (info.state === 'none') {
        promise = $q.when();
      } else if (_.isEmpty(info.id) && info.isNew) {
        promise = Utils.confirm({
          title: 'Do you want to discard your changes?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK'
        })
        .then(function() {
          return service.removeObject(info);
        });
      } else {
        promise = service.save();
      }

      return promise
        .then(function(res) {
          info.ignoreExit = true;
          return res;
        });
    };

    service.removeAll = function(info) {
      return Drafts.findAllForId(info.model._id)
        .then(function(data) {
          return $q.all(_.map(data, function(doc) {
            return Drafts.remove(doc._id);
          }));
        })
        .catch(function(err) {
          Notify.error(err);
        });
    };

    service.safeRemoveAll = function(id) {
      return Drafts.findAllForId(id)
        .then(function(data) {
          return $q.all(_.map(data, function(doc) {
            return Drafts.remove(doc._id)
              .catch(function() {
                // Ignore any errors here;
              });
          }));
        })
        .catch(function() {
          // Ignore any errors here;
        });
    };

    return service;
  }

  AutoSaveService.$inject = [
    '$log',
    '$q',
    '$rootScope',
    '$location',
    'DraftsService',
    'FeaturesService',
    'UtilsService',
    'AuthService',
    'NotifyService',
    'AUTOSAVE_EVENTS'
  ];

  angular.module('component.drafts')
    .factory('AutoSaveService', AutoSaveService);
})();
