(function() {
  'use strict';

  function RoleController($state, $stateParams, $scope, Security, Roles, Relations, Users, Utils,
    Auth, Notify, Form, FeaturesService, PERMISSIONS) {
    var ctrl = this;

    function getRestrictionForm() {
      return new Form([
        {
          id: 'filters',
          type: 'filters',
          label: '',
          helpText: 'Leave empty to show all events',
          required: false
        }
      ]);
    }

    Security.hasPermission('roles.edit')
      .then(function() {
        ctrl.showSave = true;
        if ($stateParams.id) {
          ctrl.showDuplicate = true;
        }
      });


    // allow fry and imperial to use importer for now
    ctrl.permissions = PERMISSIONS;
    ctrl.unknownPermissions = [];
    if ($stateParams.id) {
      // edit
      const features = FeaturesService.enabledFeatures().map(f => f.id);
      Roles.getForEdit($stateParams.id, { cached: false })
        .then(function(role) {
          ctrl.origPermissions = angular.copy(role.permissions || []);
          var validPermissions = _.filter(PERMISSIONS, permission => {
            if (permission.feature && !features.includes(permission.feature)) {
              return false;
            }
            return true;
          }).map(permission => permission.name);
          ctrl.invitableDomains = (role.invitableDomains || []).join('\n');

          ctrl.unknownPermissions = _.filter(ctrl.origPermissions, function(permission) {
            if (!permission) {
              return false;
            }

            return _.indexOf(validPermissions, permission) === -1;
          });

          // clean permission list.
          role.permissions = _.filter(role.permissions, function(permission) {
            return !_.isUndefined(permission) && _.indexOf(validPermissions, permission) > -1;
          });

          role.restrictions = role.restrictions || {};
          role.restrictions['events.view'] = role.restrictions['events.view'] || {};
          role.restrictions['events.view'].events = role.restrictions['events.view'].events || {};
          ctrl.restData = role.restrictions['events.view'].events;
          ctrl.role = role;
          ctrl.restForm = getRestrictionForm();

          Utils.setPageTitle('Edit role: ' + ctrl.role.title);
          ctrl.loaded = true;
        });

      Security.hasPermission('roles.delete')
        .then(function() {
          ctrl.showDelete = true;
        });
    } else {
      // new
      Utils.setPageTitle('New role');
      if ($stateParams.defaults) {
        ctrl.role = $stateParams.defaults;
      } else {
        ctrl.role = {};
        ctrl.role.permissions = [];
        ctrl.role.roles = [];
      }

      if (_.isUndefined(ctrl.role.restrictions) || _.isEmpty(ctrl.role.restrictions)) {
        ctrl.role.restrictions = {
          'events.view': { events: {} }
        };
      }

      ctrl.restData = ctrl.role.restrictions['events.view'].events;
      ctrl.restForm = getRestrictionForm();
      ctrl.invitableDomains = '';

      ctrl.loaded = true;
    }

    ctrl.relations = [];
    Relations.findAll()
      .then(function(data) {
        ctrl.relations = data;
      });

    Roles.findAll()
      .then(function(data) {
        ctrl.roles = _.chain(data)
          .map(function(item) {
            return {
              _id: item.doc._id,
              title: item.doc.title
            };
          })
          .filter(function(item) {
            if (!$stateParams.id) {
              return true;
            }

            return item._id !== $stateParams.id;
          })
          .sortBy('title')
          .value();
      });

    $scope.$watch(function() {
      return ctrl.role && ctrl.role.invitable;
    }, function(newValue, oldValue) {
      if (newValue === true &&
        oldValue === false &&
        (ctrl.role.relations.length > 0 || ctrl.role.isUserDependent)) {
        Utils.swal({
          title: 'An invitable role cannot be user or roles dependent. Upon save all ' +
          'dependencies will be removed. Are you sure you want to remove them?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          cancelButtonText: 'No, preserve it!'
        }, function(isConfirm) {
          if (!isConfirm) {
            ctrl.role.invitable = false;
          } else {
            ctrl.role.relations = [];
            ctrl.role.isUserDependent = false;
          }
        });
      }
    });

    ctrl.loadAuditlog = function() {
      Roles.getAuditlog($stateParams.id)
        .then(function(data) {
          ctrl.auditlog = data;
        });
    };

    ctrl.save = function(form) {
      ctrl.formIsSubmitted = true;
      if (form.$valid) {
        // var actionName = 'modified';
        // if (ctrl.role._rev === undefined) {
        //   actionName = 'created';
        // }

        // Utils.recordLog(ctrl.role, actionName, Auth.currentUser());
        var role = angular.copy(ctrl.role);

        role.type = 'role'; // Make sure this is role and not something else
        role.organisation = Auth.currentOrganisation();

        role.appliesToRoles = _.uniq(ctrl.role.appliesToRoles);

        role.invitableDomains = _.filter(
            // eslint-disable-next-line
            _.map(ctrl.invitableDomains.split(new RegExp('[,;\n ]', 'g')),
              function(x) {
                return x.trim();
              }
            ),
            Boolean
        );

        role.permissions = _(ctrl.unknownPermissions).concat(role.permissions).value();

        // Clear relations and userdependency if invitable
        if (role.invitable) {
          role.relations = [];
          role.isUserDependent = false;
        }

        // Clear restrictions if permission is removed
        if (role.permissions.indexOf('events.view') === -1) {
          delete role.restrictions['events.view'];
        }

        var prom;
        if (role._rev === undefined) {
          prom = Roles.create(role);
        } else {
          prom = Roles.save(role);
        }

        prom
          .then(function() {
            ctrl.formIsSaved = true;
            Notify.success('Role successfully saved', 'Success!');
            $state.go('epf.roles.index');
          })
          .catch(Utils.showError);
      } else {
        Notify.error('Form is not valid');
      }
    };

    ctrl.remove = function() {
      Users.countByRole($stateParams.id)
        .then(function(count) {
          var title = 'Are you sure you want to remove this role';
          if (count > 0) {
            title += ' and un-assign it from ' + count + ' users';
          } else {
            title += ' (no users have it assigned)';
          }
          title += ' ?';

          Utils.swal({
            title: title,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'OK'
          },
          function(isConfirm) {
            if (isConfirm) {
              Roles.remove($stateParams.id)
                .then(function(result) {
                  var msg = 'Role removed and ' + result.role_assigned_count + ' users with it ' +
                    ' have been unassigned successfully!';
                  Notify.success(msg, 'Success!');
                  $state.go('epf.roles.index');
                })
                .catch(Utils.showError);
            }
          });
        });
    };

    ctrl.duplicate = function() {
      var duplicateDoc = angular.copy(ctrl.role);
      delete duplicateDoc._id;
      delete duplicateDoc._rev;
      duplicateDoc.title = 'Copy of ' + ctrl.role.title;

      $state.go('epf.roles.new', { defaults: duplicateDoc });
    };

    ctrl.addSubRole = function() {
      ctrl.role.roles = _.isArray(ctrl.role.roles) ? ctrl.role.roles : [];
      ctrl.role.roles.push({
        _id: Utils.guid(),
        title: '',
        description: '',
        roles: [],
        permissions: []
      }
      );
    };

    ctrl.removeNode = function(scope) {
      scope.remove();
    };

    ctrl.toggleNode = function(scope) {
      scope.toggle();
    };

    ctrl.newSubNode = function(scope) {
      var nodeData = scope.$modelValue;
      nodeData.roles.push({
        _id: Utils.guid(),
        title: '',
        description: '',
        roles: [],
        permissions: []
      });
    };

    /* Handle Permissions Selector */

    var findRole = function(roles, id) {
      var role = _.find(roles, { _id: id });
      if (_.isUndefined(role)) {
        for (var i = 0; i < roles.length; i++) {
          return findRole(roles[i].roles, id);
        }
      }

      return role;
    };

    ctrl.selectAllPermissions = function(id) {
      var role = _.isString(id) ? findRole(ctrl.role.roles, id) : ctrl.role;
      role.permissions = [];
      _.forEach(ctrl.permissions, function(permission) {
        role.permissions.push(permission.name);
      });
    };

    ctrl.clearAllPermissions = function(id) {
      var role = _.isString(id) ? findRole(ctrl.role.roles, id) : ctrl.role;
      role.permissions = [];
    };
  }

  RoleController.$inject = [
    '$state',
    '$stateParams',
    '$scope',
    'SecurityService',
    'RolesService',
    'RelationsService',
    'UsersService',
    'UtilsService',
    'AuthService',
    'NotifyService',
    'FormsService',
    'FeaturesService',
    'PERMISSIONS'
  ];

  angular.module('component.roles')
    .controller('RoleController', RoleController);
})();
