(function() {
  'use strict';

  function ImporterTasksService(EasApiStore, EasApi) {
    var service = new EasApiStore('importer');
    var apiVersion = 'v2';

    service.getAvailableEventTypes = function() {
      var url = service.listUrl() + 'event/available-event-types';
      return EasApi.get(url, {}, {}, { version: apiVersion });
    };

    service.importerSearchOrigin = function(originId, state) {
      var url = service.listUrl() + 'search/origin/' + originId;
      if (state) {
        url += '/' + state;
      }
      return EasApi.get(url, {}, {}, { version: apiVersion });
    };

    return service;
  }

  ImporterTasksService.$inject = ['EasApiStore', 'EasApiService'];

  angular.module('blocks.asyncTasks')
    .factory('ImporterTasksService', ImporterTasksService);
})();
