<div #kaizen>
    <div data-ui-view>
        <div class="row mt-6">
            <div class="col-xs-12 text-center">
            <span aria-hidden="true" class="pinwheel-loading__container">
                <span class="pinwheel-loading__line"></span>
                <span class="pinwheel-loading__line"></span>
                <span class="pinwheel-loading__line"></span>
                <span class="pinwheel-loading__line"></span>
                <span class="pinwheel-loading__line"></span>
                <span class="pinwheel-loading__line"></span>
            </span>
            <div id="inner-content">
                <h2>risr/advance is loading ...</h2>
            </div>
            </div>
        </div>
    </div>
</div>