(function() {
  'use strict';

  function EventAIFillerComponent(EventTypes) {
    var ctrl = this;

    ctrl.$onInit = function() {
      console.log('AI', ctrl.model, ctrl.def);
      this.loadFormDefinition();
    };

    ctrl.$onChanges = function(changes) {
      console.log('AI', changes);
    };

    ctrl.changed = function(data) {
      _.assign(ctrl.model.fields, data);
      console.log('AI', data);
    };

    ctrl.loadFormDefinition = function() {
      return EventTypes.getFormDefinition(ctrl.eventType, ctrl.section).then(function(def) {
        ctrl.def = def;
      });
    };
  }

  EventAIFillerComponent.$inject = [
    'EventTypesService'
  ];

  angular.module('events.directives')
        .component('eventAiFiller', {
          templateUrl: 'app/components/events/directives/event-ai-filler.component.html',
          controller: EventAIFillerComponent,
          bindings: {
            model: '=',
            eventType: '<',
            section: '<'
          }
        });
})();
