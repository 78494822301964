import { Injectable } from "@angular/core";
import { set, get, del } from 'idb-keyval';

@Injectable({
    providedIn: 'root'
})
export class KeyvalService {

    popItem(key: string): any {
        return get(key).then(item => {
            return del(key).then(() => {
                return item;
            });
        });
    }

    getItem(key: string): any {
        return get(key);
    }

    setItem(key: string, value: any): Promise<void> {
        return set(key, value);
    }

}