import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ServerConfig {

    config: any;

    constructor() {
        this.config = window['kzConfig'];
    }
}