import { Injectable, inject } from '@angular/core';
import { Api } from './api';
import { AuthService } from './auth.service';
import memoize from 'memoizee-decorator';

export interface HasPermission {
    has: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    private api = inject(Api);
    private auth = inject(AuthService);

    @memoize({ promise: true, maxAge: 10000 })
    async hasPermission(permission: string): Promise<boolean> {
        const result = await this.api.get<HasPermission>(`/security/has_permission/${permission}`);
        if (result.state === 'ok') {
            return result.data.has;
        }
        return false;
    }

    async hasPermissionFor(permission: string, user: string): Promise<boolean> {
        const result = await this.api.get<HasPermission>(`/security/has_permission/${permission}/${user}`);
        if (result.state === 'ok') {
            return result.data.has;
        }
        return false;
    }

    async hasOwnPermissionFor(permission: string, user: string): Promise<boolean> {
        if (user === this.auth.currentUser()) {
            return await this.hasPermission(`{{permission}}.own`);
        }
        return await this.hasPermissionFor(permission, user);
    }

    @memoize({ promise: true, maxAge: 10000 })
    async hasRole(role: string): Promise<boolean> {
        const result = await this.api.get<HasPermission>(`/security/has_role/${role}`);
        if (result.state === 'ok') {
            return result.data.has;
        }
        return false;
    }

    // userHasRole(user: string, role: string): boolean {
    //     return true;
    // }

    async hasRoleFor(user: string, role: string): Promise<boolean> {
        const result = await this.api.get<HasPermission>(`/security/has_role_for/${role}/${user}`);
        if (result.state === 'ok') {
            return result.data.has;
        }
        return false;
    }
}
