import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, computed, effect, inject, signal } from '@angular/core';
import { AiFormFillerService } from './ai-form-filler.service';
import { CommonModule } from '@angular/common';
import { SecurityService } from '@fry/core/security.service';
import { MatButton } from '@angular/material/button';

/*
  TODO:
  - Add a progress bar that shows the progress of the form filling process.
  - Add a button to start the form filling process.
  - Add a button to stop the form filling process.

  The flow:
  - User clicks start button.
  - Speech recognition is activated.
  - Progress bar is shown.
  - User speaks.

  a)
  - User clicks stop button.
  - Speech is transcribed.
  - The transcription is sent to the backend.
  - The backend processes the transcription and returns the form data.

  b)
  - User clicks start button.
  - Speech recognition is activated.
  - Progress bar is shown.
  - User speaks.
  - Timeout kicks in
  - User sees stopped message in the banner
  - User clicks continue
  - Speech recognition is activated.
  - Progress bar shows listening message.

  c)
  - User clicks start button.
  - Speech recognition is activated.
  - Progress bar shows listening message.
  - User speaks.
  - Timeout kicks in
  - User sees stopped message in the banner
  - User clicks apply
  - The transcription is sent to the backend.
  - The backend processes the transcription and returns the form data.

  d)
  - User clicks start button.
  - Speech recognition is activated.
  - Progress bar shows listening message.
  - User speaks.
  - Timeout kicks in
  - User clicks discard
  - Last transcript is removed from the master transcript.
*/  

@Component({
  selector: 'app-ai-form-filler',
  templateUrl: './ai-form-filler.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatButton,
  ],
  providers: [
    AiFormFillerService,
  ]
})
export class AiFormFillerComponent implements OnInit {

  @Input() formDefinition!: any;
  @Output() formDataChange = new EventEmitter<any>();

  $has_permission = signal<boolean>(false);

  private security = inject(SecurityService);

  private aiFormFillerService = inject(AiFormFillerService);
  public $recognitionState = this.aiFormFillerService.$recognitionState;
  public $recordingTimeLeft = this.aiFormFillerService.$recordingTimeLeft;

  public $masterTranscript = this.aiFormFillerService.$masterTranscript;
  public $bannerVisible = computed(() => ['listening', 'stopped'].includes(this.$recognitionState().state));  

  $progressBarState = signal<{
    state: 'hidden' | 'visible';
    message: string;
  }>({
    state: 'hidden',
    message: ''
  });

  public $isButtonDisabled = computed(() => this.$recognitionState().state === 'processing');

  constructor() {
    effect(() => {
      this.formDataChange.emit(this.aiFormFillerService.$formData());
    });
  }

  ngOnInit() {
    this.security.hasPermission('features.ai-form-filler').then(has_permission => {
        if (has_permission) {
            this.$has_permission.set(true);
            this.aiFormFillerService.initSpeachRecognition();
        }
    });
  }

  ngOnChanges() {
    console.log("Form definition changed:", this.formDefinition);
    this.aiFormFillerService.setFormSpec(this.formDefinition);
  }

  handleMainBtnClick() {
    if (this.$recognitionState().state === 'listening') {
      this.aiFormFillerService.stopSpeechRecognition();
      setTimeout(() => {
        this.aiFormFillerService.processTranscript();
      }, 1500);
    } else {
      this.aiFormFillerService.startSpeechRecognition();
    }
  }

  handleContinueBtnClick() {  
    if (this.$recognitionState().state !== 'listening') {
      this.aiFormFillerService.startSpeechRecognition();
    } else {
      this.aiFormFillerService.extendRecording();
    }
  }

  handleDiscardBtnClick() {
    this.aiFormFillerService.stopSpeechRecognition();
    this.aiFormFillerService.discardTranscript();
  }

  handleApplyBtnClick() {
    this.aiFormFillerService.processTranscript();;
  }

  ngOnDestroy() {
    this.aiFormFillerService.stopSpeechRecognition();
  }
}
