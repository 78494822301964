<section class="wrapper">
    <header class="row">
        <h1>
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2" viewBox="0 0 512 512" width="0.8em" height="0.8em">
            <path d="M343.464 380.301 218.792 170.488v-.39l-.219-122.953h8.61c12.906 0 23.406-10.485 23.406-23.422 0-12.922-10.5-23.406-23.406-23.406H102.261c-12.922 0-23.406 10.484-23.406 23.406 0 12.937 10.484 23.422 23.406 23.422h7.531l-.25 122.984L1.605 380.301c-6.688 24.984 8.14 50.656 33.109 57.344h275.641c25-6.688 39.828-32.36 33.109-57.344Zm-37.281 41.687-267.313-.297c-16.64-4.468-26.515-21.593-22.062-38.218l108.547-212.75.328-139.188h-23.407c-4.296 0-7.796-3.5-7.796-7.812 0-4.313 3.5-7.797 7.796-7.797h124.922c4.297 0 7.782 3.5 7.782 7.797 0 4.312-3.485 7.812-7.782 7.812h-23.406l.391 138.672L328.23 383.738c4.468 16.672-5.422 33.797-22.047 38.25Z" style="fill:#001f33;fill-rule:nonzero" transform="translate(58.124 3.842) scale(1.15196)"/>
            <path d="M193.062 14.832s-7.931 17.231-28.88 7.285c-18.267-8.672-30.407-4.464-43.134-.311-30.383 14.127-42.86-6.974-42.86-6.974L.578 167.801c-2.25 8.328 2.703 16.89 11.031 19.125h257.766c8.328-2.235 13.281-10.781 11.047-19.125l-87.36-152.969Z" style="fill:#54d9cc;fill-rule:nonzero" transform="translate(91.16 254.339) scale(1.18098)"/>
          </svg>
          risr/labs
        </h1>
    </header>
    <div class="row">
        <div class="col-xs-12">
            <p>Labs enables you to opt in and out of AI and innovation features across the risr/ platform,
                providing a flexible environment for you to explore and adapt to new functionality at your convenience.
                With labs, you can optimise your software experience in the risr/platform to meet your specific needs.
            </p>
            <p class="box box-info">Initially labs features will not have an associated cost.  In the future, advanced or enhanced
                features may be offered under different pricing options. We’ll keep you updated with any changes.
            </p>
        </div>
    </div>
</section>


<eas-response-wrapper [response]="featureResponse()">
    @if (spotlightFeatures().length > 0) {
        <div class="row">
            @for (feature of spotlightFeatures(); track feature.id) {
                <div class="col-xs-12">
                    <eas-feature-settings [feature]="feature"></eas-feature-settings>
                </div>
            }
        </div>
    }
    @if (normalFeatures().length > 0) {
        <div class="row">
                @for (feature of normalFeatures(); track feature.id) {
                    <div class="col-md-6">
                        <eas-feature-settings [feature]="feature"></eas-feature-settings>
                    </div>
                }
        </div>
    }
</eas-response-wrapper>
