export class Organisation {
    id: string;
    name: string;
    shortName: string;

    enabledLabs: boolean;

    constructor(doc) {
        this.id = doc.id
        this.name = doc.name;
        this.shortName = doc.shortName;
        this.enabledLabs = doc.enabledLabs;
    }
}
