
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { RouterOutlet } from '@angular/router';
import { MainComponent } from './main.component';
import { NetworkService } from './core/network.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, MainComponent],
  providers: [
  ]
})
export class AppComponent {

  private sw = inject(SwUpdate);
  private network = inject(NetworkService);

  constructor() {
    this.network.resetMode();
    this.setupServiceWorker();
  }

  setupServiceWorker(): void {
    console.log("SW", this.sw.isEnabled);
    window['offlineState'] = {
      engine: 'serviceWorker',
      state: this.sw.isEnabled ? 'ready' : 'none',
    }

    this.sw.versionUpdates.subscribe(event => {
      console.log("SW", event);
      console.log("SW", this.sw.isEnabled);
    });
    this.sw.unrecoverable.subscribe(event => {
      console.log("SW", event);
    });
  }

}
