(function() {
  'use strict';

  function UserAccountsController($scope, $uibModal, Accounts, Auth, Form, Notify, Users, Security,
                                  Utils) {
    var ctrl = this;

    ctrl.accountId = Users.remoteUser || Auth.currentUser();
    ctrl.isOwn = ctrl.accountId === Auth.currentUser();
    ctrl.remoteUser = Users.remoteUser;
    ctrl.action = $scope.action;
    ctrl.credentials = $scope.credentials || [];
    ctrl.readableName = {
      local: 'Username',
      proxy: 'SSO ID',
      api: 'API'
    };
    ctrl.newlyCreatedAPICredential = null;
    ctrl.copyMessageVisible = false;
    ctrl.copyToClipboard = function() {
      var text = `- public_key: ${ctrl.newlyCreatedAPICredential.public_key}\n
        - secret_key: ${ctrl.newlyCreatedAPICredential.secret_key}`;
      navigator.clipboard.writeText(text)
        .catch(function(err) {
          console.error('Could not copy text: ', err);
        });

      ctrl.newlyCreatedAPICredential = null;
    };

    ctrl.showActions = typeof $scope.showActions !== 'undefined' ? $scope.showActions : true;

    function loadPerm() {
      var auth;
      if (ctrl.action === 'create') {
        auth = Security.hasPermission('users.create');
      } else if (ctrl.isOwn) {
        auth = Security.hasPermission('credential.manage.own');
      } else {
        auth = Security.hasPermissionFor('credential.manage', ctrl.accountId);
      }
      return auth
        .then(function() {
          ctrl.canManage = true;
        })
        .catch(function() {
          ctrl.canManage = false;
        });
    }

    ctrl.perm = {
      edit: function(_credential) {
        return ctrl.action === 'create';
      },
      editUsername: function(_credential) {
        if (ctrl.action === 'create' || _credential.type === 'api') {
          return false;
        }

        return ctrl.canManage;
      },
      resetPwd: function(credential) {
        if (ctrl.action === 'create') {
          return false;
        }

        if (ctrl.isOwn) {
          return false;
        }

        // This should handle SSO integrations
        if (credential.type !== 'local') {
          return false;
        }

        return ctrl.canManage;
      },
      changePwd: function(credential) {
        if (ctrl.action === 'create') {
          return false;
        }

        // This should handle SSO integrations
        if (credential.type !== 'local') {
          return false;
        }

        if (ctrl.isOwn) {
          return true;
        }

        return ctrl.canManage;
      },
      remove: function(_credential) {
        return ctrl.canManage;
      }
    };

    if (_.indexOf(['view', 'edit'], ctrl.action) > -1) {
      loadPerm()
        .then(function() {
          return Accounts.find(ctrl.accountId);
        })
        .then(function(credentials) {
          ctrl.credentials = credentials;
        })
        .catch(function(err) {
          // Catch it here so that it does nto ruin the whole widget
          console.log('Could not load account:', err);
        })
        .finally(function() {
          ctrl.loaded = true;
        });
    } else {
      loadPerm()
        .finally(function() {
          ctrl.loaded = true;
        });
    }

    ctrl.openAccountForm = function(credential) {
      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/users/partials/credential-form.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'credential',
          'action',
          function($scope, $uibModalInstance, credential, action) {
            $scope.action = action;
            if (_.isUndefined(credential)) {
              $scope.newCreds = true;
            }

            $scope.credential = angular.copy(credential || {});

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function(isValid) {
              if (!isValid) {
                return;
              }

              if ($scope.action === 'create') {
                // do not save to the backend, wait the main form to save
                if ($scope.newCreds) {
                  ctrl.credentials.push($scope.credential);
                } else {
                  _.assignIn(credential, $scope.credential);
                }
                $uibModalInstance.dismiss('cancel');
              } else if ($scope.action === 'edit') {
                // It shouldn't be possible to edit, so it's always creating a new one
                if (!$scope.newCreds) {
                  throw new Error('This form cannot be used for editing');
                }
                // save straight away to the backend
                var actionName = 'add_credential',
                    accountId = ctrl.accountId;
                Accounts.updateCredentials(actionName, accountId, $scope.credential)
                  .then(function(credentials) {
                    credential = credentials[credentials.length - 1];
                    if (credential.type === 'api') {
                      ctrl.newlyCreatedAPICredential = credential;
                    }

                    ctrl.credentials = credentials;
                    Notify.success('The account has been added successfully');
                    $uibModalInstance.dismiss('cancel');
                  })
                  .catch(function(e) {
                    Notify.error(e.message, 'An error occurred while adding the credential');
                  });
              }
            };
          }
        ],
        size: 'md',
        resolve: {
          credential: function() {
            return credential;
          },
          action: function() {
            return ctrl.action;
          }
        }
      });
    };

    ctrl.openEditForm = function(credential, action) {
      $uibModal.open({
        animation: true,
        templateUrl: 'app/components/users/partials/edit-cred-form.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'credential',
          'action',
          'AuthService',
          'UsersService',
          function($scope, $uibModalInstance, credential, action, Auth, Users) {
            $scope.isOwn = _.isEmpty(Users.remoteUser) || Auth.currentUser() === Users.remoteUser;
            $scope.credential = angular.copy(credential);
            if (action === 'editUsername') {
              $scope.credential.oldUsername = $scope.credential.username;
            }

            $scope.form = new Form();

            if (action === 'editUsername') {
              $scope.form.addField({
                id: 'useIntegration',
                type: 'boolean',
                label: 'Would you like to also edit this account in your CRM?',
                required: true,
                hideExpression: function(_$viewValue, _$modelValue, $scope) {
                  if (_.isUndefined($scope.model.type)) {
                    return true;
                  }

                  var integrations = Accounts.getIntegrations();
                  var hasIntegration = !_.isUndefined(integrations[Auth.currentOrganisation()]);
                  return !($scope.model.type === 'proxy' && hasIntegration);
                }
              });
              $scope.form.addField({
                id: 'username',
                label: $scope.credential.type === 'local' ? 'New Username' : 'New SSO ID',
                type: 'username',
                required: true
              });

              // $scope.form.addField({
              //   id: '_id',
              //   label: 'Short description',
              //   type: 'string',
              //   required: true
              // });
            } else if (action === 'editPassword') {
              if ($scope.isOwn) {
                $scope.form.addField({
                  id: 'oldPassword',
                  label: 'Old Password',
                  type: 'password',
                  required: true
                });
              }

              $scope.form.addField(
                {
                  id: 'newPassword',
                  type: 'password',
                  label: 'New Password',
                  helpText: 'The password should be at least 12 characters long and contain at ' +
                    'least 1 uppercase letter, 1 lowercase letter, 1 number and 1 following' +
                    ' special character: !?=#*$@+-.',
                  required: true,
                  validators: {
                    isStrong: {
                      expression: function(viewValue, modelValue) {
                        var value = viewValue || modelValue;
                        if (value === undefined) { return false; }
                        if (value.length < 12) { return false; }
                        if (value.match(/[0-9]/) === null) { return false; }
                        if (value.match(/[a-z]/) === null) { return false; }
                        if (value.match(/[A-Z]/) === null) { return false; }
                        return value.match(/[!?=#*$@+-]/) !== null;
                      },
                      message: '"Please choose a stronger password"'
                    }
                  }
                }
              );

              $scope.form.addField({
                id: 'newPassword2',
                label: 'Re-type New Password',
                type: 'password',
                required: true
              });
            }

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };

            $scope.save = function(isValid) {
              if (!isValid) {
                return;
              }

              var actionName,
                  accountId = ctrl.accountId,
                  data;
              if (action === 'editUsername') {
                actionName = 'edit_credential_username';
                data = {
                  type: $scope.credential.type,
                  old_username: $scope.credential.oldUsername,
                  new_username: $scope.credential.username
                };
              } else if (action === 'editPassword') {
                if ($scope.credential.newPassword !== $scope.credential.newPassword2) {
                  Notify.error('Passwords do not match.');
                  return;
                }

                actionName = 'edit_credential_password';
                data = {
                  type: $scope.credential.type,
                  username: $scope.credential.username,
                  old_password: $scope.credential.oldPassword,
                  new_password: $scope.credential.newPassword,
                  new_password2: $scope.credential.newPassword2
                };
              }


              Accounts.updateCredentials(actionName, accountId, data)
                .then(function() {
                  credential.username = $scope.credential.username;
                  Notify.success('The credential has been edited successfully!');
                  $uibModalInstance.dismiss('cancel');
                })
                .catch(function(e) {
                  Notify.error(e.message, 'An error occurred while editing the credential');
                });
            };
          }
        ],
        size: 'md',
        resolve: {
          credential: function() {
            return credential;
          },
          action: function() {
            return action;
          }
        }
      });
    };

    ctrl.resetPassword = function(credential) {
      Utils.swal({
        title: 'This will send an email to this user containing a link for them to follow to ' +
        'reset their password. Would you like to continue?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue'
      },
      function(isConfirm) {
        if (isConfirm) {
          var data = {
            account_id: ctrl.accountId,
            organisation: Auth.currentOrganisation(),
            username: credential.username
          };
          Accounts.requestResetPassword(data)
            .then(function() {
              Notify.success('Password reset email sent');
            })
            .catch(Utils.showError);
        }
      });
    };

    ctrl.remove = function(index) {
      var credential = ctrl.credentials[index];
      if (ctrl.action === 'create') {
        ctrl.credentials.splice(index, 1);
      } else {
        Utils.swal({
          title: 'Are you sure you want to remove this credential?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Remove'
        },
          function(isConfirm) {
            if (isConfirm) {
              var actionName = 'remove_credential';
              var accountId = ctrl.accountId;
              Accounts.updateCredentials(actionName, accountId, credential)
                .then(function() {
                  ctrl.credentials.splice(index, 1);
                  Notify.success('The account has been removed successfully!');
                })
                .catch(function(e) {
                  Notify.error(e.message, 'An error occurred while removing the account');
                });
            }
          });
      }
    };
  }

  UserAccountsController.$inject = [
    '$scope',
    '$uibModal',
    'AccountsService',
    'AuthService',
    'FormsService',
    'NotifyService',
    'UsersService',
    'SecurityService',
    'UtilsService'
  ];

  function UserAccountsDirective() {
    return {
      scope: {
        action: '@',
        credentials: '=',
        showActions: '=?'
      },
      restrict: 'AE',
      templateUrl: 'app/components/users/directives/userAccounts.html',
      replace: true,
      controller: UserAccountsController,
      controllerAs: 'ctrl'
    };
  }

  angular.module('component.users')
    .directive('userCredentials', UserAccountsDirective)
    .controller('UserAccountsController', UserAccountsController);
})();
