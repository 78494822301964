import { Injectable, inject } from "@angular/core";
import { BroadcastService } from "./broadcast.service";

export enum NetworkStatus {
    online = 'online',
    offline = 'offline'
}

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    public modeStatus: NetworkStatus = NetworkStatus.online;
    public networkStatus: NetworkStatus = NetworkStatus.online;

    private broadcastService = inject(BroadcastService);

    setModeOffline(): void {
        if (this.modeStatus !== NetworkStatus.offline) {
            this.modeStatus = NetworkStatus.offline;
            this.broadcastService.broadcast('BecomeOffline');
        }
    }

    setModeOnline(): void {
        if (this.modeStatus !== NetworkStatus.online) {
            this.modeStatus = NetworkStatus.online;
            this.broadcastService.broadcast('BecomeOnline');
        }
    }

    resetMode(): void {
        this.modeStatus = !navigator.onLine ? NetworkStatus.offline : NetworkStatus.online;
    }

    setOffline(): void {
        if (this.networkStatus !== NetworkStatus.offline) {
            console.log("NetworkService: setOffline");
            this.networkStatus = NetworkStatus.offline;
        }
        this.setModeOffline();
    }

    setOnline(): void {
        if (this.networkStatus !== NetworkStatus.online) {
            console.log("NetworkService: setOnline");
            this.networkStatus = NetworkStatus.online;
            this.broadcastService.broadcast('BrowserBecomeOnline');
        }
    }

    isOffline(): boolean {
        return this.modeStatus === NetworkStatus.offline;
    }

    isNetworkOffline(): boolean {
        return this.networkStatus === NetworkStatus.offline;
    }

    forceOnline(): Promise<void> {
        console.log("NetworkService: forceOnline");
        if (this.isOffline()) {
            return Promise.reject("This is not available when working offline");
        }
        return Promise.resolve();
    }

    forceNetworkOnline(): Promise<void> {
        console.log("NetworkService: forceNetworkOnline");
        if (this.isNetworkOffline()) {
            return Promise.reject("This is not available when working offline");
        }
        return Promise.resolve();
    }
}