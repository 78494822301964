import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {


    private fromJson(value: string | null): any {
        if (value === null) {
            return {};
        }

        try {
            return JSON.parse(value);
        } catch (e) {
            console.error(`LocalStorageService: Error parsing value ${value}`);
            return {};
        }
    }

    popItem(key: string): any {
        try {
            const item = localStorage.getItem(key);
            localStorage.removeItem(key);
            if (item === null) {
                return {};
            }
            return this.fromJson(item);
        } catch (e) {
            console.error(`LocalStorageService: Error getting value ${key}`);
            return {};
        }
    }

    getItem(key: string): any {
        try {
            const item = localStorage.getItem(key);
            return this.fromJson(item);
        } catch (e) {
            console.error(`LocalStorageService: Error getting value ${key}`);
            return {};
        }
    }

    setItem(key: string, value: any): void {
        try {
            const json = JSON.stringify(value);
            localStorage.setItem(key, json);
        } catch (e) {
            console.error(`LocalStorageService: Error setting value ${value}`);
        }
    }
}