import { Injectable, computed, inject } from '@angular/core';
import { OidcService } from './oidc.service';
import { of } from 'rxjs';
import { LocalStorageService } from './localstorage.service';
import { BroadcastService } from './broadcast.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private oidc = inject(OidcService);
    private localStorage = inject(LocalStorageService);
    private broadcast = inject(BroadcastService);

    public preferredMode: string = 'online';
    private localSettings: any = null

    public currentUser = computed(() => {
        const user = this.oidc.currentUser();
        if (user !== null) {
            return user.username;
        }
        return null
    });

    public hasCurrentUser(): Promise<boolean> {
        if (this.currentUser() === null) {
            return Promise.reject({ status: 401, message: 'User is not logged in'})
        }
        return Promise.resolve(true);
    }

    public currentOrganisation(): string {
        return this.oidc.organisation;
    }

    public async logout(): Promise<void> {
        return this.oidc.logout();
    }

    public async isLoggedIn(): Promise<boolean> {
        // FIXME - handle offline
        return this.oidc.isLoggedIn();
    }

    public setOrganisation(organisation: string): void {
        this.oidc.setOrganisation(organisation);
    }

    public unsetOrganisation(): void {
        this.oidc.setOrganisation(null);
    }

    public async ensureLoggedIn(): Promise<void> {
        // FIXME - implement properly
        return this.oidc.ensureLoggedIn();
    }

    public async getStorageMode(): Promise<string> {
        return 'persistent';
    };

    public getSettings(): any {
        const currentUser = this.currentUser();
        if (!currentUser) {
            console.log('Trying to get settings for anonymous user');
            return {};
        }

        const key = 'settings_' + currentUser;
        const settings = this.localStorage.getItem(key);

        // Load defaults
        settings.preferredMode = settings.preferredMode || 'online';
        return settings;
    }

    public setSettings(settings: any): void {
        const currentUser = this.currentUser();
        if (!currentUser) {
            console.log('Trying to set settings for anonymous user');
            return;
        }

        settings.preferredMode = !settings.preferredOffline ? 'online' : 'offline';

        const key = 'settings_' + currentUser;
        this.localStorage.setItem(key, settings);

        // FIXME - This was broadcasting the settings with it
        this.broadcast.broadcast('SettingsChanged');
    }

    public setLocalSettings(settings: any): Promise<void> {
        const currentUser = this.currentUser();
        if (!currentUser) {
            console.log('Trying to set local settings for anonymous user');
            return Promise.resolve();
        }

        var key = 'kz_settings_' + currentUser;
        this.localStorage.setItem(key, settings);
        this.localSettings = settings;
        // FIXME - This was broadcasting the settings with it
        this.broadcast.broadcast('LocalSettingsChanged');
        return Promise.resolve();
    }

    public getLocalSettings(): Promise<any> {
        if (this.localSettings !== null) {
            return Promise.resolve(this.localSettings);
        }

        const currentUser = this.currentUser();
        if (!currentUser) {
            console.log('Trying to get local settings for anonymous user');
            return Promise.resolve({});
        }
        const key = 'kz_settings_' + currentUser;
        const settings = this.localStorage.getItem(key);
        this.localSettings = settings;
        return Promise.resolve(settings);
    }

    public setStorageMode(mode: string): Promise<void> {
        return this.getLocalSettings().then(settings => {
            settings.preferredMode = mode;
            return this.setLocalSettings(settings);
        });
    }

    public processSettings(): void {
        const settings = this.getSettings();
        this.preferredMode = settings.preferredMode;
    }

    public getAllowSyncInOffline(): Promise<boolean> {
        return of(true).toPromise();
    }


    public isLoggedInBy() {
        return this.oidc.currentUser().masqueradeBy;
    };

};