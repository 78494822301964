@switch (response().state) {
    @case ('empty') {
    <div class="row">
        <div class="col-xs-12">
            <p>Loading...</p>
        </div>
    </div>
    }
    @case ('error') {
    <div class="row">
        <div class="col-xs-12">
            <p>Error loading features</p>
            <pre>{{ response().error }}</pre>
        </div>
    </div>
    }
    @case ('ok') {
        <ng-container *ngTemplateOutlet="content"></ng-container>
    }
}

<ng-template #content>
    <ng-content></ng-content>
</ng-template>