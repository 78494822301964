import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
}


const fetchConfig = function fetchConfig(): Promise<void> {
  window['kzConfig'] = {};
  return fetch('__config__', { cache: 'no-store' })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      console.log('Config: Fetched new config from server');
      window['kzConfig'] = data;
      try {
        window.localStorage.setItem('kzConfig', JSON.stringify(data));
      } catch (err) {
        console.log('Config: Could not save kzConfig', err);
      }
    })
    .catch(function (error) {
      console.log(error);
      // Try get config from local storage
      try {
        var config = window.localStorage.getItem('kzConfig');
        if (config) {
          window['kzConfig'] = JSON.parse(config);
          console.log('Config: Recovered config from local storage');
        }
      } catch (err) {
        console.log('Config: Could not recover config from local storage');
      }
    })
    .then(function () {
      console.log('Config: Using configuration', window['kzConfig']);
    })
    .then(function () {
      return fetch('__version__')
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          window['kzVersion'] = data;
          console.log('Config: Loaded version', window['kzVersion']);
        })
        .catch(function (err) {
          console.log(err);
        });
    });
}

const handleQueryParams = function handleQueryParams(): void {
  const urlParams = new URLSearchParams(window.location.search);
  const org = urlParams.get('org');
  const kzFast = urlParams.get('fast_redirect');
  if (org) {
    console.log('Query: Setting organisation', org);
    try {
      window.localStorage.setItem('currentOrganisation', org);
    } catch (err) {
      console.log('Query: Could not save organisation', err);
    }
    urlParams.delete('org');
  }

  if (kzFast) {
    console.log('Query: Fast redirect to', kzFast);
    try {
      window.sessionStorage.setItem('fast_redirect', kzFast);
    } catch (err) {
      console.log('Query: Could not save fast redirect', err);
    }
    urlParams.delete('fast_redirect');
  }

  const url = new URL(window.location.href);
  url.search = urlParams.toString();
  console.log('Query: Updating URL', url);
  window.history.replaceState({}, document.title, url);
}


const initApp = function initApp(): Promise<void> {
  handleQueryParams();
  return fetchConfig();
}

initApp().then(function () {
  bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));
});
