(function() {
  'use strict';

  function Card($rootScope) {
    return {
      restrict: 'A',
      template: '<options-used options="options" ng-if="options"></options-used>',
      link: function($scope) {
        $rootScope.$on('BulkActionViewLoaded', function(_evt, args) {
          $scope.options = args.options;
          $scope.options.dryrun = args.dryrun;
        });
      }
    };
  }

  Card.$inject = ['$rootScope'];

  angular.module('component.bulkActions')
    .directive('optionsUsedCard', Card);
})();
