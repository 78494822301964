(function() {
  'use strict';

  const PARTS = ['display', 'sources', 'filterForm'];


  class SQLReportHumanComponent {
    constructor(
      EventTypesService,
      BlueprintsService,
      RelationsService,
      BlueprintMapsService
    ) {
      this.EventTypesService = EventTypesService;
      this.BlueprintsService = BlueprintsService;
      this.RelationsService = RelationsService;
      this.BlueprintMapsService = BlueprintMapsService;
      this.visible = false;
    }

    toggle() {
      this.visible = !this.visible;
    }

    $onChanges(changes) {
      if (changes.conf && changes.conf.currentValue) {
        this.load(JSON.parse(changes.conf.currentValue));
      } else {
        this.parts = [];
      }
    }

    async load(conf) {
      try {
        const ids = this.collectIds(conf);
        if (this.idsEqual(ids, this.ids)) {
          return;
        }
        this.ids = ids;
        this.related = await this.loadRelated(ids);
        this.parse(conf);
      } catch (e) {
        console.error('Error parsing', e);
        this.parts = [];
        this.error = e;
      }
    }

    idsEqual(ids1, ids2) {
      return _.isEqual(ids1, ids2);
    }

    parse(conf) {
      if (!conf) {
        this.parts = [];
        return;
      }

      console.log('Parsing', conf);
      this.parts = _.filter(_.map(PARTS, (part) => {
        const parser = `${part}Parser`;
        if (this[parser]) {
          return {
            name: part,
            data: this[parser](conf[part])
          };
        }
      }), Boolean);
    }

    sourcesParser(sources) {
      const result = _.map(sources, (source) => this.sourceParser(source));
      return result;
    }

    sourceParser(source) {
      const result = {
        id: source.id,
        source: source.source
      };

      if (source.filters) {
        result.filters = _.map(source.filters, (filter) => {
          return this.filterParser(filter);
        });
      }

      if (source.columns) {
        result.columns = _.map(source.columns, (column) => {
          return this.columnParser(column);
        });
      }

      return result;
    }

    filterParser(filter) {
      const result = {
        id: filter.id,
        field: filter.field,
        operator: filter.operator,
        value: filter.value
      };

      if (_.isString(filter.value) && filter.value.indexOf('__context__') === 0) {
        result.resolved = ['Value from form: ' + filter.value.replace('__context__', '')];
      }

      if (_.isArray(filter.value)) {
        result.resolved = _.map(filter.value, (value) => {
          return this.related.eventTypes[value] || value;
        });
      }

      return result;
    }

    columnParser(column) {
      const result = {
        source: column.source,
        field: column.field,
        label: column.label || column.field
      };

      if (column.source === 'blueprintTag') {
        if (column.blueprints) {
          result.resolved = _.map(column.blueprints, (bp) => {
            return this.related.blueprints[bp] || bp;
          });
        } else {
          result.resolved = [this.related.blueprints[column.field] || column.field];
        }
      }
      if (column.source === 'blueprintMap') {
        if (column.maps) {
          result.resolved = _.map(column.maps, (bp) => {
            return this.related.blueprintMaps[bp] || bp;
          });
        } else {
          result.resolved = [this.related.blueprintMaps[column.field] || column.field];
        }
        result.value_type = column.value_type;
        result.key_source = column.key_source;
        result.key = column.key;
      }
      if (column.source === 'userRelation') {
        if (column.relations) {
          result.resolved = _.map(column.relations, (bp) => {
            return this.related.relations[bp] || bp;
          });
        } else {
          result.resolved = [this.related.relations[column.field] || column.field];
        }
      }
      return result;
    }

    collectIds(conf) {
      const ids = {
        eventTypes: [],
        blueprints: [],
        relations: [],
        userFields: [],
        blueprintMaps: []
      };
      _.forEach(conf.sources, (source) => {
        _.forEach(source.filters, (filter) => {
          const data = this.collectFromFilter(filter);
          this.mergeIds(ids, data);
        });

        _.forEach(source.columns, (column) => {
          const data = this.collectFromColumn(column);
          this.mergeIds(ids, data);
        });
      });
      return ids;
    }

    mergeIds(ids, data) {
      _.forEach(data, (value, key) => {
        if (!ids[key]) {
          ids[key] = [];
        }
        ids[key] = _.uniq([...ids[key], ...value]);
      });
    }

    collectFromFilter(filter) {
      const ids = {};
      if (filter.source === 'blueprintTag') {
        if (filter.blueprints) {
          ids.blueprints = filter.blueprints;
        } else {
          ids.blueprints = [filter.field];
        }
      }

      if (filter.source === 'eventRelation') {
        ids.relations = [filter.field];
      }

      if (filter.source === 'blueprintMap') {
        if (filter.maps) {
          ids.blueprintMaps = filter.maps;
        } else {
          ids.blueprintMaps = [filter.field];
        }
      }

      if (filter.source === 'event' && filter.field === 'event_type') {
        if (filter.value && filter.value.indexOf('__context__') === -1) {
          ids.eventTypes = filter.value;
        }
      }


      return ids;
    }

    collectFromColumn(column) {
      const ids = {};
      if (column.source === 'blueprintTag') {
        if (column.blueprints) {
          ids.blueprints = column.blueprints;
        } else {
          ids.blueprints = [column.field];
        }
      }

      if (column.source === 'eventRelation') {
        ids.relations = [column.field];
      }

      if (column.source === 'userRelation') {
        ids.relations = [column.field];
      }

      if (column.source === 'blueprintMap') {
        if (column.maps) {
          ids.blueprintMaps = column.maps;
        } else {
          ids.blueprintMaps = [column.field];
        }
      }

      return ids;
    }

    async loadRelated(ids) {
      const result = {};
      if (ids.eventTypes.length) {
        const data = await this.EventTypesService.getGroupsByIds(ids.eventTypes, 'stub');
        const ets = {};
        _.forEach(data, (item) => {
          ets[item.versionGroupId] = item.name;
        });
        result.eventTypes = ets;
      }
      if (ids.blueprints.length) {
        const data = await this.BlueprintsService.findByIds(ids.blueprints);
        const bps = {};
        _.forEach(data, (item) => {
          bps[item.doc._id] = item.doc.name;
        });
        result.blueprints = bps;
      }
      if (ids.blueprintMaps.length) {
        const data = await this.BlueprintMapsService.findByIds(ids.blueprintMaps);
        const bps = {};
        _.forEach(data, (item) => {
          bps[item.doc._id] = item.doc.name;
        });
        result.blueprintMaps = bps;
      }
      if (ids.relations.length) {
        const data = await this.RelationsService.findByIds(ids.relations);
        const bps = {};
        _.forEach(data, (item) => {
          bps[item.doc._id] = item.doc.name;
        });
        result.relations = bps;
      }
      return result;
    }
  }

  SQLReportHumanComponent.$inject = [
    'EventTypesService',
    'BlueprintsService',
    'RelationsService',
    'BlueprintMapsService'
  ];
  angular.module('component.reports')
    .component('sqlReportHuman', {
      templateUrl: 'app/components/reports/directives/sql-report-human.html',
      controller: SQLReportHumanComponent,
      bindings: {
        conf: '<'
      }
    });
})();
