@if ($has_permission()) {
<div>
  <h2>AI Form Assistant</h2>
</div>
<div>
  @if ($recognitionState().alertMessage) {
    <div>
      <p>{{ $recognitionState().alertMessage }}</p>
    </div>
  }
  @if ($recognitionState().state !== 'unavailable') {
    <ul class="list-inline list-unstyled">
      <li><button class="btn btn-primary" *ngIf="$recognitionState().buttonLabel" [disabled]="$isButtonDisabled()" type="button" id="main-btn" (click)="handleMainBtnClick()">{{ $recognitionState().buttonLabel }}</button></li>
      <li><button class="btn btn-primary btn-outline" *ngIf="$recognitionState().state === 'listening'" type="button" id="discard-btn" (click)="handleDiscardBtnClick()">Discard</button></li>
    </ul>
  }
  @if ($bannerVisible()) {
    <div class="fixed inset-x-0 top-0 w-screen bg-info-200 text-info-800 z-50 px-2 py-2 flex gap-2 justify-center items-center">
      <p>{{ $recognitionState().alertMessage }}</p>
      @if ($recognitionState().state === 'listening') {
        <p>Audio recording will stop in <strong>{{ $recordingTimeLeft() | date:'mm:ss' }}</strong></p>
        <button class="btn btn-info" type="button" id="extend-btn" (click)="handleContinueBtnClick()">Extend</button>
      }
      @if ($recognitionState().state === 'stopped') {
      <ul class="list-inline list-unstyled">
        <li><button class="btn btn-info" type="button" id="continue-btn" (click)="handleContinueBtnClick()">Continue Recording</button></li>
        <li><button class="btn btn-primary" type="button" id="apply-btn" (click)="handleApplyBtnClick()">Apply</button></li>
        <li><button class="btn btn-warning" type="button" id="discard-btn" (click)="handleDiscardBtnClick()">Discard</button></li>
      </ul>
      }
    </div>
  }
</div>
}
