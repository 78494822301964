<section class="wrapper">
    <header class="row">
        <h1>risr/free</h1>
    </header>
</section>

@if (canManageFeatures()) {
<eas-response-wrapper [response]="featureResponse()">
    <div class="row">
        @for (feature of features(); track feature.id) {
            <div class="col-xs-12">
                <eas-feature-settings [feature]="feature"></eas-feature-settings>
            </div>
        }
    </div>
</eas-response-wrapper>
}
@else {
    <section class="wrapper">
        <div class="row">
            <div class="col-xs-12">
                <p>You do not have permission to view protected features.</p>
            </div>
        </div>
    </section>
}