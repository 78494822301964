(function() {
  'use strict';

  function SlotController($scope, $state, $uibModal, LocalizationService, TASK_NAMES,
                          BULK_ACTIONS_STATES) {
    var ctrl = this;

    ctrl.dateFormat = LocalizationService.getDateTimeFormat('datetime');
    ctrl.BULK_ACTIONS_STATES = BULK_ACTIONS_STATES;
    ctrl.TASKS_NAMES = TASK_NAMES;
    if (!_.isUndefined($scope.item.dryrun)) {
      ctrl.dryrun = $scope.item.dryrun;
    } else {
      ctrl.dryrun = true;
      // retro-compatibility
      if (!_.isUndefined($scope.item.data) && !_.isUndefined($scope.item.data.options)) {
        ctrl.dryrun = $scope.item.data.options.dryrun;
      }
    }

    var taskName;
    if (!_.isUndefined($scope.item.data)) {
      taskName = $scope.item.data.name;
    } else {
      taskName = TASK_NAMES[$scope.item.action];
    }

    // this may be also used for the details view, move it if necessary
    var isDraft = $scope.item.state === 'draft';
    ctrl.actionButtons = [
      {
        label: isDraft ? 'Edit' : 'View',
        ariaLabel: isDraft ? 'Edit' + taskName : 'View ' + taskName,
        icon: 'icon-edit',
        href: (function() {
          let href;
          if ($scope.item.state === 'draft') {
            href = $state.href('epf.bulk-actions.edit', { id: $scope.item._id });
          } else {
            href = $state.href('epf.bulk-actions.view', { id: $scope.item._id });
          }
          return href;
        })(),
        klass: 'text-info'
      }
    ];

    // this should probably go to preview
    function openPreview(item) {
      $uibModal.open({
        animation: true,
        size: 'md',
        templateUrl: 'app/components/bulk-actions/bulk-action-preview.html',
        controller: [
          '$scope',
          '$uibModalInstance',
          'name',
          'options',
          'meta',
          'dryrun',
          function($scope, $uibModalInstance, name, options, meta, dryrun) {
            $scope.name = name;
            $scope.meta = meta;
            $scope.options = options;
            $scope.options.dryrun = dryrun;

            $scope.dismiss = function() {
              $uibModalInstance.dismiss('cancel');
            };
          }
        ],
        resolve: {
          name: function() {
            return item.data.name;
          },
          options: function() {
            return item.data.options;
          },
          meta: function() {
            return item.meta;
          },
          dryrun: function() {
            return ctrl.dryrun;
          }
        }
      });
    }


    ctrl.secondaryActionButtons = [
      {
        label: 'Show bulk action details',
        ariaLabel: 'Show bulk action details for ' + taskName,
        icon: 'icon-list-bullet',
        onClick: function() {
          openPreview($scope.item);
        },
        klass: 'text-info'
      }
    ];
  }

  function SlotDirective() {
    return {
      scope: {
        item: '='
      },
      restrict: 'AE',
      templateUrl: 'app/components/bulk-actions/bulk-action-slot.html',
      controller: SlotController,
      controllerAs: 'ctrl'
    };
  }

  SlotController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    'LocalizationService',
    'TASK_NAMES',
    'BULK_ACTIONS_STATES'
  ];

  angular.module('component.bulkActions')
    .directive('bulkActionSlot', SlotDirective)
    .controller('bulkActionSlotController', SlotController);
})();
