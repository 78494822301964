import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface BroadcastMessage {
    msg: string;
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class BroadcastService {

    private _messages$: Subject<BroadcastMessage> = new Subject();
    public messages$ = this._messages$.asObservable();

    broadcast(msg: string, data?: any): void {
        this._messages$.next({ msg: msg, data: data });
    }
}