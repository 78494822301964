(function() {
  'use strict';

  var mainMenu = [
    {
      title: 'Dashboard',
      extendBy: 'dashboard.index',
      links: [
        {
          title: 'Dashboard',
          state: 'dashboard.index',
          placeholder: true
        },
        {
          title: 'Manage dashboard templates',
          state: 'epf.dashboard-templates.index',
          permission: 'dashboardTemplates.view'
        },
        {
          title: 'Manage summary templates',
          state: 'epf.profile-templates.index',
          permission: 'profileTemplates.view'
        }
      ]
    },
    {
      title: 'Timeline',
      extendBy: 'epf.events.index',
      links: [
        {
          title: 'Timeline',
          state: 'epf.events.index',
          permission: 'events.view.own',
          placeholder: true
        },
        {
          title: 'Manage categories',
          state: 'epf.events.manage-timelines',
          permission: 'organisation.manage-timelines'
        }
      ]
    },
    {
      title: 'Files',
      state: 'epf.files.index',
      permission: 'events.view.own'
    },
    {
      title: 'Content',
      links: [
        {
          title: 'FAQs',
          state: 'epf.faqs.index',
          permission: 'faqs.view'
        },
        {
          title: 'Event types',
          state: 'epf.event-types.index',
          permission: 'eventTypes.view'
        },
        {
          title: 'Blueprints',
          state: 'epf.blueprints.index',
          permission: 'blueprints.view'
        },
        {
          title: 'Blueprint Maps',
          state: 'epf.blueprintMaps.index',
          permission: 'blueprints.view',
          requireFeature: 'blueprint-maps'
        },
        {
          title: 'Announcements',
          state: 'epf.announcements.index',
          permission: 'announcements.view'
        },
        {
          title: 'Email templates',
          state: 'epf.email-templates.index',
          permission: 'emailTemplates.view'
        },
        {
          title: 'Bulk actions',
          state: 'epf.bulk-actions.index',
          permission: [
            'BulkActionsService',
            function(BulkActionsService) {
              return BulkActionsService.getAvailableBulkActions()
                .then(function(availableBulkActions) {
                  return !_.isEmpty(availableBulkActions);
                });
            }
          ]
        }
      ]
    },
    {
      title: 'Goals',
      extendBy: 'epf.goals.index',
      links: [
        {
          title: 'Goals',
          state: 'epf.goals.index',
          permission: 'goals.work.own',
          placeholder: true
        },
        {
          title: 'Manage categories',
          state: 'epf.goals.manage-categories',
          permission: 'organisation.manage-categories'
        }
      ]
    },
    {
      title: 'Reports',
      links: [
        {
          title: 'Reports',
          state: 'epf.reportTemplates.index',
          permission: 'reports.view'
        }
      ]
    },
    {
      title: 'User management',
      links: [
        {
          title: 'Users',
          state: 'epf.users.index',
          permission: 'users.view'
        },
        {
          title: 'All events',
          state: 'epf.allevents.index',
          permission: 'events.view'
        },
        {
          title: 'Pending sections',
          state: 'epf.admin.pending-sections',
          permission: [
            'AdminService', 'SecurityService',
            function(AdminService, Security) {
              // Implementing or below - lets check the permission first
              // if no permission try the reviewable sections
              return Security.hasPermission('events.reviewPending')
                .catch(function() {
                  return AdminService.getReviewableSections()
                    .then(function(sections) {
                      return sections.length > 0;
                    });
                });
            }
          ]
        },
        {
          title: 'User fields',
          state: 'epf.user-fields.index',
          permission: 'userFields.view'
        },
        {
          title: 'Relations',
          state: 'epf.relations.index',
          permission: 'relations.view'
        },
        {
          title: 'Roles',
          state: 'epf.roles.index',
          permission: 'roles.view'
        },
        {
          title: 'Organisations',
          state: 'epf.organisations.index',
          permission: ['SecurityService', function(Security) {
            return Security.hasRole('system:superadmin');
          }]
        },
        {
          title: 'Example',
          state: 'epf.example.index',
          permission: 'users.view',
          requireFeature: 'example'
        }
      ]
    },
    {
      title: 'Settings',
      links: [
        {
          title: 'Labs settings',
          state: 'epf.settings.labs',
          permission: ['SecurityService', 'OrganisationService',
            function(Security, OrganisationService) {
              if (!OrganisationService.currentOrganisation()?.enabledLabs) {
                return false;
              }

              return Security.hasPermission('features.manage')
                .catch(function() {
                  return false;
                });
            }
          ]
        },
        {
          title: 'Organisation settings',
          state: 'epf.settings.organisation',
          permission: ['SecurityService', 'FeaturesService',
            function(Security, Features) {
              if (Features.freeFeatures().length === 0) {
                return false;
              }
              return Security.hasPermission('features.manage')
                .catch(function() {
                  return false;
                });
            }
          ]
        },
        {
          title: 'Protected settings',
          state: 'epf.settings.protected',
          permission: ['SecurityService', 'OrganisationService',
            function(Security) {
              return Security.hasRole('system:superadmin')
                .catch(function() {
                  return false;
                });
            }
          ]
        }
      ]
    }
      ],
      menu = {
        main: mainMenu
      };

  angular.module('epf.layout')
    .constant('HEADER_MENU', menu);
})();
