(function() {
  'use strict';

  function BulkActionViewController(
    $interval, $rootScope, $scope, $state, $stateParams, AsyncTasks, Importer, Utils, Notify,
    TASK_NAMES
  ) {
    var ctrl = this;
    var bulkActionId = $stateParams.id;

    function resetPagination() {
      ctrl.outputLimit = 5;
      ctrl.outputPage = 1;
      ctrl.outputStartKey = 1;
      ctrl.outputTotal = 0;
    }
    resetPagination();

    ctrl.filterState = '__all__';
    function getBulkAction() {
      var outputOptions = {
        size: ctrl.outputLimit,
        page: ctrl.outputPage,
        filterState: ctrl.filterState || '__all__'
      };
      return AsyncTasks.findWithOutput($stateParams.id, outputOptions)
        .then(function(bulkActionDoc) {
          ctrl.bulkActionDoc = bulkActionDoc;

          var taskName = '';
          if (!_.isUndefined(bulkActionDoc.data)) {
            taskName = bulkActionDoc.data.name;
          } else {
            taskName = TASK_NAMES[bulkActionDoc.action];
          }

          Utils.setPageTitle('View bulk action task: ' + taskName);

          if (_.isUndefined(ctrl.filterState) || ctrl.filterState === '__all__') {
            if (_.isUndefined(bulkActionDoc.meta)) {
              ctrl.outputLimit = 0;
            } else {
              ctrl.outputTotal = bulkActionDoc.meta.processed;
            }
          } else if (!_.isUndefined(bulkActionDoc.outputSummary)) {
            ctrl.outputTotal = bulkActionDoc.outputSummary[ctrl.filterState];
          }

          var dryrun;
          if (ctrl.bulkActionDoc) {
            if (!_.isUndefined(bulkActionDoc.dryrun)) {
              dryrun = bulkActionDoc.dryrun;
            } else {
              // retro-compatibility
              dryrun = bulkActionDoc.data.options.dryrun;
            }
          }

          var meta = bulkActionDoc.meta || {},
              data = bulkActionDoc.data || {},
              options = data.options || {};
          $rootScope.$broadcast(
            'BulkActionViewLoaded',
            { meta: meta, options: options, dryrun: dryrun }
          );

          return bulkActionDoc;
        });
    }

    $scope.filterState = '__all__';
    $scope.$watchCollection('filterState', function(newFilterState) {
      resetPagination();
      if (!_.isUndefined(newFilterState)) {
        ctrl.filterState = newFilterState;
      }
      getBulkAction();
    });

    var refreshTaskInterval;
    var stopTaskRefresh = function() {
      if (angular.isDefined(refreshTaskInterval)) {
        $interval.cancel(refreshTaskInterval);
        refreshTaskInterval = undefined;
      }
    };

    function startInterval() {
      ctrl.bulkActionDoc = undefined;
      refreshTaskInterval = $interval(function() {
        getBulkAction(bulkActionId)
          .then(function(bulkActionDoc) {
            if (_.indexOf(['new', 'processing'], bulkActionDoc.state) === -1) {
              stopTaskRefresh();
            }
          });
      }, 5000);
    }
    startInterval();

    $scope.$on('$destroy', function() {
      stopTaskRefresh();
    });

    ctrl.downloadOutput = function(bulkActionId) {
      // this is only available for import_csv for now as they are no dryrun for others
      AsyncTasks.importerDownloadOutput(bulkActionId);
    };

    ctrl.abortBulkAction = function(bulkActionId) {
      AsyncTasks.abort(bulkActionId)
        .then(getBulkAction)
        .then(function() {
          Notify.success(
            'Abortion imminent.',
            'The system is checking every 5 iterations if the bulk action can be aborted.'
          );
        })
        .catch(function(err) {
          Notify.error('Error while aborting bulk action', err);
        });
    };

    ctrl.runForReal = function(bulkActionData, bulkActionId) {
      Utils.swal({
        title: 'Are you sure you would like to run this import for real?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK'
      },
      function(isConfirm) {
        if (isConfirm) {
          var dryrun = false;

          var prom;
          if (_.isEmpty(bulkActionData)) {
            prom = Importer.reImportFileWithoutChanges(bulkActionId, dryrun);
          } else {
            // in the case it was a dryrun and user want to directly run it for real.
            var name = bulkActionData.name,
                docType = bulkActionData.docType,
                extra = { eventTypeId: bulkActionData.eventTypeId },
                bulkFileName = bulkActionData.bulkFileName,
                mapping = bulkActionData.mapping,
                files = {},
                eUniqueness = bulkActionData.entry_uniqueness,
                options = bulkActionData.options;

            if (bulkActionData.extra && bulkActionData.extra.event_type_id) {
              extra = { eventTypeId: bulkActionData.extra.event_type_id };
            }

            prom = Importer.importFile(
              name,
              docType,
              extra,
              bulkFileName,
              files,
              mapping,
              eUniqueness,
              options,
              dryrun,
              bulkActionId
            );
          }

          prom.then(function() {
            Notify.success('Import executed for real');
            startInterval(); // restart interval as the previous task was finished.
          });
        }
      });
    };

    ctrl.retryImport = function(importId) {
      // this is only available for import_csv for now as they are no dryrun for others
      $state.go('epf.bulk-actions.edit', { id: importId });
    };

    ctrl.goToPage = function(page) {
      ctrl.outputPage = page;
      ctrl.outputStartKey = page * ctrl.outputLimit;
      getBulkAction();
    };
  }

  BulkActionViewController.$inject = [
    '$interval',
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'AsyncTasksService',
    'ImporterService',
    'UtilsService',
    'NotifyService',
    'TASK_NAMES'
  ];

  angular.module('component.bulkActions')
    .controller('BulkActionViewController', BulkActionViewController);
})();
