angular.module('component.bulkActions')
  .component('optionsUsed', {
    templateUrl: 'app/components/bulk-actions/components/options-used.html',
    controller: function() {
      var ctrl = this;

      function update(options) {
        if (_.isUndefined(options) || _.isEmpty(options) || !options) {
          return;
        }

        var readableState = { true: 'Yes', false: 'No' };
        var dryrunReadableState = { true: 'Mock run', false: 'Real run' };
        var actionReadableState = {
          createOnlyIfNotExists: 'Search for existing data, create new records which do not ' +
          'exist, do not update existing data',
          forceCreate: 'Create all records in the file, do not check for existing data',
          createOrUpdate: 'Search for existing data, create new records which do not exist, ' +
          'update existing data',
          updateOnly: 'Search for existing data, do not create new records, update existing data'
        };

        var linkExistingEventsToTargets = options.linkExistingEventsToTargets || false;
        ctrl.readableOptions = {
          Action: actionReadableState[options.action],
          'Type of bulk action': dryrunReadableState[options.dryrun],
          'Email notifications': readableState[options.sendEmails],
          'Link existing events to targets': readableState[linkExistingEventsToTargets]
        };
      }

      this.$onInit = function() {
        update(ctrl.options);
      };

      this.$onChanges = function(changes) {
        update(changes.options.currentValue);
      };
    },
    bindings: {
      options: '<'
    }
  });
