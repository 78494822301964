(function() {
  'use strict';

  function LoginDirective($log, $location, $window, kzLocalStorage,
                          Auth, Form) {
    return {
      restrict: 'E',
      templateUrl: 'app/components/accounts/login.form.html',
      scope: {
        organisation: '=',
        success: '&',
        error: '&',
        options: '=?'
      },
      link: function(scope) {
        if (scope.organisation === null) {
          throw new Error({ status: 500, message: 'Cannot login without organisation' });
        }

        scope.options = scope.options || {};
        scope.credentials = {};

        /**
         * Button click handler to handle external login via SSO options.
         * @param  {Object} login The login object with the correct options,
         *                        including the loginPath.
         * @return {void}
         */
        scope.loginExternal = function(login) {
          var cameFrom = $location.search().came_from || '/';
          kzLocalStorage.setItem('kz-cameFrom', { url: cameFrom });
          kzLocalStorage.setItem('kz-login', login);
          $window.location.href = login.loginPath;
        };

        scope.getExternalUrl = function(login) {
          return login.loginPath;
        };

        /**
         * Create the form with the correct fields for the login form.
         * @return {Object}     The created form.
         */
        var createForm = function() {
          var fields = [];
          fields.push({
            id: 'username',
            type: 'string',
            label: 'Username',
            required: true,
            ngModelAttrs: {
              autocorrect: {
                bound: 'autocorrect',
                attribute: 'autocorrect'
              },
              autocapitalize: {
                bound: 'autocapitalize',
                attribute: 'autocapitalize'
              }
            },
            ngModelAttrsValues: [
              {
                name: 'autocorrect',
                value: 'off'
              },
              {
                name: 'autocapitalize',
                value: 'off'
              }
            ]
          });

          fields.push({
            id: 'password',
            type: 'password',
            label: 'Password',
            required: true
          });

          return new Form(fields);
        };
        scope.loginForm = createForm();

        /**
         * Login the user as well as set any errors and warnings.
         * @return {void}
         */
        scope.loginUser = function() {
          // After submitting the form we want to clear all notifications
          scope.notifications = [];

          // Make sure we remove kz-login if using the normal one
          kzLocalStorage.popItem('kz-login');
          scope.authenticating = true;

          var credentials = scope.credentials;
          var forceCurrentUser = scope.options.forceCurrentUser ? Auth.currentUser() : undefined;
          Auth.login(scope.organisation.id, credentials.username,
            credentials.password, { force: scope.options.force, forceUsername: forceCurrentUser })
            .then(function() {
              if (scope.success) {
                scope.success();
              }
              // var cameFrom = $location.search().came_from || '/';
              // $location.url(cameFrom);
            }, function(error) {
              if (error.status === 401) {
                scope.notifications.push({
                  type: error.status,
                  classes: 'text-danger',
                  msg: error.message
                });
              } else if (error.status === 403) {
                scope.notifications.push({
                  type: error.status,
                  classes: 'text-danger',
                  msg: 'Access was denied. Please make sure cookies are enabled.'
                });
              } else if (error.status === 509) {
                if (scope.error) {
                  scope.error({ error: error });
                }
                // Notify.error('Redirecting to offline login');
                // $state.go('accounts.pin');
              } else {
                scope.notifications.push({
                  type: error.status,
                  classes: 'text-danger',
                  msg: error.message || 'Unknown error'
                });
                $log.error('Unknown error:' + error.message);
              }
              scope.authenticating = false;
            })
            .finally(function() {
            });
        };
      }
    };
  }

  LoginDirective.$inject = [
    '$log',
    '$location',
    '$window',
    'kzLocalStorage',
    'AuthService',
    'FormsService'
  ];

  angular.module('component.accounts')
    .directive('kzLoginForm', LoginDirective);
})();
