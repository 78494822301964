(function() {
  'use strict';

  function featureDirective(Features) {
    return {
      scope: {
        feature: '@'
      },
      transclude: true,
      templateUrl: 'app/blocks/utils/feature.html',
      link: function(scope) {
        Features.isEnabled(scope.feature)
          .then(function(res) {
            scope.showFeature = res;
          })
          .catch(function(err) {
            console.log(err);
            scope.showFeature = false;
          });
      }
    };
  }

  featureDirective.$inject = ['FeaturesService'];

  angular.module('blocks.utils')
    .directive('kzFeatureEnabled', featureDirective);
})();
