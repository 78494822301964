var templates = {
  string: require('./types/string.html').default,
  password: require('./types/password.html').default,
  string_list: require('./types/string_list.html').default,
  email_multiple: require('./types/email_multiple.html').default,
  email_select: require('./types/email_select.html').default,
  email: require('./types/email.html').default,
  email_list: require('./types/email_list.html').default,
  typeahead: require('./types/typeahead.html').default,
  numeric: require('./types/numeric.html').default,
  boolean: require('./types/boolean.html').default,
  date: require('./types/date.html').default,
  datetime: require('./types/datetime.html').default,
  select: require('./types/select.html').default,
  discrete: require('./types/discrete.html').default,
  discrete_multiple: require('./types/discrete_multiple.html').default,
  text: require('./types/text.html').default,
  text_heading: require('./types/text_heading.html').default,
  text_text: require('./types/text_text.html').default,
  tree: require('./types/tree.html').default,
  likert: require('./types/likert.html').default,
  multi: require('./types/multi.html').default,
  report: require('./types/report.html').default,
  goal: require('./types/goal.html').default,
  link: require('./types/link.html').default,
  wysiwyg: require('./types/wysiwyg.html').default,
  signature: require('./types/signature.html').default,
  hidden: require('./types/hidden.html').default,
  deletableItems: require('./types/deletableItems.html').default,
  checkboxes_grid: require('./types/checkboxes_grid.html').default,
  filters: require('./types/filters.html').default,
  username: require('./types/username.html').default,
  json: require('./types/json.html').default,
  file: require('./types/file.html').default
};

var label = require('./wrappers/label.html').default;
var toggleButton = require('./wrappers/toggleButton.html').default;
var hasError = require('./wrappers/hasError.html').default;
var messages = require('./wrappers/messages.html').default;
var file = require('./wrappers/file.html').default;
var wrappers = {
  label,
  toggleButton,
  hasError,
  messages,
  file
};

(function() {
  'use strict';

  var baseUrl = 'app/blocks/widgets/forms/',
    // eslint-disable-next-line no-unused-vars
      getTemplateUrl = function(name, folder) {
        return baseUrl + folder + '/' + name + '.html';
      },

      buildFieldType = function(name, wrappers) {
        return {
          name: name,
          template: templates[name],
          wrapper: wrappers
        };
      },

      buildWrapper = function(name) {
        return {
          name: name,
          template: wrappers[name]
        };
      },

      allTypes = [
        'string', 'password', 'string_list', 'email_multiple', 'email_select', 'email',
        'email_list', 'typeahead', 'numeric', 'boolean', 'date',
        'datetime', 'select', 'discrete', 'discrete_multiple', 'text', 'text_heading', 'text_text',
        'tree', 'likert', 'multi', 'report', 'goal', 'link',
        'wysiwyg', 'signature', 'hidden', 'deletableItems', 'checkboxes_grid', 'filters',
        'username', 'json', 'file'
      ],
      allWrappers = ['label', 'toggleButton', 'hasError', 'file'],
      commonWrappers = ['hasError'],
      formTypes = [],
      formWrappers = [];

  _.forEach(allTypes, function(type) {
    var wrappers;
    if (type === 'boolean') {
      wrappers = ['toggleButton'];
    } else if (type === 'file') {
      wrappers = ['file', ...commonWrappers];
    } else if (
      type.indexOf('text_') === 0 ||
      type.indexOf('link') > -1 ||
      type.indexOf('hidden') > -1
    ) {
      wrappers = [];
    } else {
      wrappers = _.union(['label'], commonWrappers);
    }

    formTypes.push(buildFieldType(type, wrappers));
  });

  _.forEach(allWrappers, function(wrapper) {
    formWrappers.push(buildWrapper(wrapper));
  });

  var dateTimeFormat = {
    inputdate: 'd/M/yyyy',
    dateonly: 'd MMM, yyyy',
    datetime: 'd MMM, yyyy H:mm:ss',
    timeonly: 'H:mm:ss'
  };

  var emptySignature = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAjgAAADcCAQAAADXNhPAAAAC' +
    'IklEQVR42u3UIQEAAAzDsM+/6UsYG0okFDQHMBIJAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDA' +
    'cwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEw' +
    'HMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEwHMBwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8' +
    'BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcCQADAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDATAcwHAADAcw' +
    'HMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHMBwAAwHMBzAcAAMBzAcAMMBDAcwHADDAQwHwHAAwwEMB8' +
    'BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMB8BwAMMBDAfAcADDATAcwHAAwwEwHMBwAAwHMBzAcAAMBzAc' +
    'AMMBDAcwHADDAQwHwHAAwwEMB8BwAMMBMBzAcADDkQAwHMBwAAwHMBwAwwEMBzAcAMMBDAfAcADDAQwHwHAAwwEwHM' +
    'BwAMMBMBzAcAAMBzAcwHAADAcwHADDAQwHMBwAwwEMB8BwAMMBMBzAcADDATAcwHAADAcwHMBwAAwHMBwAwwEMBzAc' +
    'AMMBDAegeayZAN3dLgwnAAAAAElFTkSuQmCC';

  angular.module('widgets.forms')
    .constant('ALL_TYPES', allTypes)
    .constant('FORM_TYPES', formTypes)
    .constant('FORM_WRAPPERS', formWrappers)
    .constant('DATETIME_FORMAT', dateTimeFormat)
    .constant('EMPTY_SIGNATURE', emptySignature);
})();
