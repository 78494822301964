import { Injectable, inject } from '@angular/core';
import { ClusterService } from '../core/cluster.service';
import { OidcService } from './oidc.service';

export interface Response<T> {
    state: 'error' | 'ok' | 'empty' | 'loading';
    error?: any;
    data: T;
}

@Injectable({
    providedIn: 'root'
})
export class Api {


    private oidc = inject(OidcService);
    private clusterService = inject(ClusterService);

    async get<T>(path: string) {
        return this.call<T>('GET', path);
    }

    async post<T>(path: string, body?: any) {
        return this.call<T>('POST', path, body);
    }

    async call<T>(method: string, path: string, body?: any): Promise<Response<T>> {
        const apiInfo = this.oidc.apiInfo();

        const urls = this.clusterService.getCurrentUrls();
        try {
            const response = await fetch(urls.api + path, {
                method: method,
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiInfo.token,
                    'EAS-Organisation': apiInfo.organisation || '',
                    'EAS-Username': apiInfo.username || '',
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Unknown error');
            }
            return {
                state: 'ok',
                data: data
            }
        } catch (error) {
            return {
                state: 'error',
                error: error,
                data: null
            }
        }
    }
}
