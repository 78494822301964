import { Injectable, computed, inject, signal } from '@angular/core';
import { Api } from '@fry/core/api';
import { Feature } from './feature';
import { Response } from '@fry/core/api';

const emptyResponse = { state: 'empty', data: [] } as Response<Feature[]>;
const loadingResponse = { state: 'loading', data: [] } as Response<Feature[]>;


@Injectable({
    providedIn: 'root'
})
export class FeaturesService {

    private api = inject(Api);

    public featureResponse = signal<Response<Feature[]>>(emptyResponse);
    public features = computed(() => {
        const response = this.featureResponse();
        return response.state === 'ok' ? response.data : [];
    });
    public enabledFeatures = computed(() => this.features().filter(f => f.status.enabled));

    public labsFeatures = computed(() => this.features().filter(f => f.availableIn === 'labs'));
    public freeFeatures = computed(() => this.features().filter(f => f.availableIn === 'free'));
    public protectedFeatures = computed(() => this.features().filter(f => f.availableIn === 'protected'));

    public groupedFeatures = {
        labs: this.labsFeatures,
        free: this.freeFeatures,
        protected: this.protectedFeatures
    };

    private mapping = {
        'labs': this.labsFeatures,
        'free': this.freeFeatures,
        'protected': this.protectedFeatures
    }

    getByAvailableIn(availableIn: 'labs' | 'free' | 'protected') {
        return this.mapping[availableIn];
    }

    async fetchFeatures() {
        if (this.featureResponse().state === 'loading') {
            return;
        }

        if (this.featureResponse().state === 'empty') {
            this.featureResponse.set(loadingResponse);
        }

        const features = await this.api.get<Feature[]>('/features/')
        if (features.state !== 'ok') {
            this.featureResponse.set({ state: 'error', error: features.error, data: [] });
            return;
        }
        features.data = features.data.map(f => new Feature(f));
        this.featureResponse.update((_currFeatures) => features);
    }

    async enableFeature(feature: Feature) {
        await this.api.post(`/features/${feature.id}/enable`);
        await this.fetchFeatures();
    }

    async disableFeature(feature: Feature) {
        await this.api.post(`/features/${feature.id}/disable`);
        await this.fetchFeatures();
    }

    async isEnabled(featureId: string) {
        if (this.featureResponse().state !== 'ok') {
            await this.fetchFeatures();
        }
        if (this.featureResponse().state !== 'ok') {
            return false;
        }

        const feature = this.features().find(f => f.id === featureId);
        return feature !== undefined ? feature.status.enabled : false;
    }

    async ensureFeatures() {
        if (this.featureResponse().state !== 'ok') {
            await this.fetchFeatures();
        }
    }

    invalidateFeatures() {
        this.featureResponse.set(emptyResponse);
    }
}
