import { Injectable, inject } from '@angular/core';
import { Api } from './api';
import { KeyvalService } from './keyval.service';

export interface Response<T> {
    state: 'error' | 'ok' | 'empty' | 'loading';
    error?: any;
    data: T;
}

@Injectable({
    providedIn: 'root'
})
export class CachedApi {

    private api = inject(Api);
    private keyval = inject(KeyvalService);

    async get<T>(path: string) {
        try {
            const result = await this.api.get<T>(path);
            if (result.state != 'ok') {
                throw new Error(result.error || 'Unknown error');
            }
            await this.keyval.setItem(path, result);
            return result;
        } catch (error) {
            console.log(`Failed fetching ${path}`, error);
            const cached = await this.keyval.getItem(path);
            if (cached) {
                console.log('Returning cached value for', path);
                return cached;
            }
            throw error;
        }
    }

    async post<T>(path: string, body?: any) {
        const result = await this.api.post<T>(path, body);
        return result;
    }

}
