(function() {
  'use strict';

  function DashboardController(DashboardTemplates, DashboardUtils, Utils) {
    var ctrl = this;

    ctrl.showNotifications = true;

    ctrl.name = 'main-dashboard';
    ctrl.templates = [];

    DashboardTemplates.findAllForUser()
      .then(function(templates) {
        // Templates come ordered, so the first one is always either the default
        // or the one that was last used (KZ-2293)
        ctrl.template = DashboardUtils.setTemplate('dashboards', templates[0]);

        if (ctrl.template.title !== 'Default dashboard') {
          Utils.setPageTitle(ctrl.template.title);
        } else {
          Utils.setPageTitle('Dashboard');
        }
        // We also set all the dashboard templates the user has access to
        ctrl.templates = templates;
      })
      .catch(Utils.showError);

    ctrl.onTemplateChange = function(id) {
      var template = _.find(ctrl.templates, { _id: id });
      if (!_.isUndefined(template)) {
        ctrl.template = DashboardUtils.setTemplate('dashboards', template);
        Utils.setPageTitle(ctrl.template.title);
      }
    };
  }

  DashboardController.$inject = [
    'DashboardTemplatesService',
    'DashboardUtilsService',
    'UtilsService'
  ];

  angular.module('component.dashboard')
    .controller('DashboardController', DashboardController);
})();
