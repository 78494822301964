import { Injectable, inject, signal } from "@angular/core";
import { Organisation } from "./organisation";
import { AuthService } from "./auth.service";
import { CachedApi } from "./cached-api";


@Injectable({
    providedIn: 'root'
})
export class OrganisationService {

    private api = inject(CachedApi);
    private auth = inject(AuthService);
    public currentOrganisation = signal<Organisation | null>(null);

    async fetchOrganisation() {
        const currentOrg = this.auth.currentOrganisation();
        if (currentOrg === null) {
            return;
        }

        try {
            const organisation = await this.api.get<Organisation>(`/organisations/${currentOrg}`);
            if (organisation.state === 'ok') {
                this.currentOrganisation.set(new Organisation(organisation.data));
            } else {
                this.currentOrganisation.set(null);
            }
        } catch (e) {
            console.log(e);
            this.currentOrganisation.set(null);
        };
    }

    async ensureOrganisation() {
        if (this.currentOrganisation() === null) {
            await this.fetchOrganisation();
        }

        if (this.currentOrganisation().id !== this.auth.currentOrganisation()) {
            await this.fetchOrganisation();
        }
    }

    invalidateOrganisation() {
        this.currentOrganisation.set(null);
    }
}