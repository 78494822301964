(function() {
  'use strict';

  function LoggedInAsBar(OidcService) {
    return {
      restrict: 'EA',
      replace: true,
      templateUrl: 'app/layout/header/logged-in-as-bar.html',
      link: function($scope) {
        const currentUser = OidcService.currentUser();
        if (currentUser.masqueradeBy) {
          $scope.original = currentUser.originalProfile;
        }

        var bodyEl = angular.element(document.getElementsByTagName('body')[0]),
            className = 'masquerade-mode';

        if ($scope.original) {
          bodyEl.addClass(className);
        } else {
          bodyEl.removeClass(className);
        }

        $scope.logout = function() {
          OidcService.logoutAs();
        };
      }
    };
  }

  LoggedInAsBar.$inject = ['OidcService'];

  angular.module('kzHeaderCard')
    .directive('kzLoggedInAsBar', LoggedInAsBar);
})();
